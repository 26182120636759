
import React, { useEffect, useRef, useState } from 'react';
import ApiServices from '../../service/api/ApiServices';
import CommonCal from '../../service/common/CommonCal';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from 'primereact/datatable';
import { CommonBodyForTable, commonCloseDialogFooter, srBodyTemplate, totalcostTemplate } from '../Common/CommonComponents';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toolbar } from 'primereact/toolbar';
import { barDataForBarChart, DataForpieChart } from '../Common/CommonFunctions';
import { Dropdown } from 'primereact/dropdown';
import { brandsOptions } from './Constants';
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import { Chart } from 'primereact/chart';
import { Avatar } from "primereact/avatar";
const apiServices = new ApiServices();
const commonCal = new CommonCal();
const colorData = ["#b84c7d", "#50b47b", "#8650a6", "#86a542", "#6881d8", "#c18739"];

// =================================================================================================
// =================================================================================================

export const RealTimeDayWise = () => {

    const durationOptions = [
        // { name: 'All', code: 'all' },
        { name: 'Custom', code: 'custom' }

    ];

    const customWise = [
        // { name: "Date Wise", code: 'datewise' },
        { name:"Date Wise", code:"datewisesuper"} // has only selcect date perticular

    ]

    const [loading, setLoading] = useState(true)
    const [globalFilter, setGlobalFilter] = useState('');
    const [dates, setDates] = useState(null)
    const [selectedDuration, setSelectedDuration] = useState(durationOptions[2])
    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedbrand, setSelectedBrand] = useState([{ name : 'TAPRI KIOSK', code : 'TAPRI'},
        { name : 'TKAFFE KIOSK', code : 'TKAFFE'}])
    const [btnVisibility, setBtnVisibility] = useState(false)

    const [chartMasterData, setChartMasterData] = useState(null)
    const [labelsData, setLabelsData] = useState(null)
    const [valuesData, setValuesData] = useState(null)
    const [detailedDialog, setDetailedDialog] = useState(false)
    const [detailedData, setDetailedData] = useState(null)
    const [masterData, setMasterData] = useState([])
    const [customFilterDialog, setCustomFilterDialog] = useState(false);
    const [lastMonthsListDropDown, setLastMonthsListDropDown] = useState([]);
    const [customType, setCustomType] = useState(null)
    const [selectedCustomMonth, setSelectedCustomMonth] = useState(null)
    const [filteredDate, setFilteredDate] = useState(null)
    const [customStart, setCustomStart] = useState('');
    const [customEnd, setCustomEnd] = useState('');
    const [zerorangemachinecount, setZeroRangeMachinecount] = useState(0)
    const [onefivehundredmachinecount, setOnefiveHundredmachinecount] = useState(0)
    const [fivehundredonetoonekmachinecount, setFivehundredonetoonekmachinecount] = useState(0)
    const [onekonetotwokmachinecount, setOnekonetotwokmachinecount] = useState(0)
    const [twokonetothreekmachinecount, setTwokonetothreekmachinecount] = useState(0)
    const [abovethreekmachinecount, setAbovethreekmachinecount] = useState(0)
    const [zerorangecupcount, setZeroRangeCupcount] = useState(0)
    const [onefivehundredcupcount, setOnefiveHundredCupcount] = useState(0)
    const [fivehundredonetoonekcupcount, setFivehundredonetoonekcupcount] = useState(0)
    const [onekonetotwokcupcount, setOnekonetotwokCupcount] = useState(0)
    const [twokonetothreekcupcount, setTwokonetothreekCupcount] = useState(0)
    const [abovethreekcupcount, setAbovethreekCupcount] = useState(0)
    // price states
    const [zerorangecupPrice, setZeroRangeCupPrice] = useState(0)
    const [onefivehundredcupPrice, setOnefiveHundredCupPrice] = useState(0)
    const [fivehundredonetoonekcupPrice, setFivehundredonetoonekcupPrice] = useState(0)
    const [onekonetotwokcupPrice, setOnekonetotwokCupPrice] = useState(0)
    const [twokonetothreekcupPrice, setTwokonetothreekCupPrice] = useState(0)
    const [abovethreekcupPrice, setAbovethreekCupPrice] = useState(0)

    const [totalMachines, setTotalMachines] = useState(0)
    const [totalCups, setTotalCups] = useState(0)
    const [totalAmount, setTotalAmount] = useState(0)

    const dt = useRef(null);
    const dtReport = useRef(null);

    useEffect(() => {
        getDates()
        getTest()
    }, [])

    const getDates = async () => {
        const response = await commonCal.getDates()
        setDates(response)
        const date = { startDate: response?.startToday, endDate: response?.endToday }
        setSelectedDate({ date: date })
        getReport({ date })
        setFilteredDate(date)
    }

    const getReport = async (data) => {
        let totalAmount = 0
        let totalCups = 0
        let totalMachines = 0

        const resdata = await apiServices.realtimecupdaywiseconsumption(data);
        console.log(resdata?.data?.ranges);

        console.log(resdata?.data?.ranges);

        setChartMasterData(resdata?.data?.ranges)
        setMasterData(resdata?.data)

        await resdata.data.ranges.forEach((obj) => {
            if (obj.title == "0 Cups") {
                setZeroRangeMachinecount(obj.count)
                totalMachines += obj.count
            } else if (obj.title == "1 to 50 Cups") {
                setOnefiveHundredmachinecount(obj.count)
                totalMachines += obj.count
            } else if (obj.title == "51 to 100 Cups") {
                setFivehundredonetoonekmachinecount(obj.count)
                totalMachines += obj.count
            } else if (obj.title == "101 to 200 Cups") {
                setOnekonetotwokmachinecount(obj.count)
                totalMachines += obj.count
            } else if (obj.title == "201 to 300 Cups") {
                setTwokonetothreekmachinecount(obj.count)
                totalMachines += obj.count
            } else if (obj.title == "Above 300 Cups") {
                setAbovethreekmachinecount(obj.count)
                totalMachines += obj.count
            }
        })

        setTotalMachines(totalMachines)

        // to set cupcount for machine count by range

        let filtered_data_range_0_to_0 = resdata.data.filtered_data_range_0_to_0.reduce((acc, val) => {
            return acc + (val.cups || 0);
        }, 0)

        setZeroRangeCupcount(filtered_data_range_0_to_0)
        totalCups += filtered_data_range_0_to_0


        let filtered_data_range_1_to_50 = resdata.data.filtered_data_range_1_to_50.reduce((acc, val) => {
            return acc + (val.cups || 0);
        }, 0)

        setOnefiveHundredCupcount(filtered_data_range_1_to_50)
        totalCups += filtered_data_range_1_to_50


        let filtered_data_range_51_to_100 = resdata.data.filtered_data_range_51_to_100.reduce((acc, val) => {
            return acc + (val.cups || 0);
        }, 0)

        setFivehundredonetoonekcupcount(filtered_data_range_51_to_100)
        totalCups += filtered_data_range_51_to_100

        let filtered_data_range_101_to_200 = resdata.data.filtered_data_range_101_to_200.reduce((acc, val) => {
            return acc + (val.cups || 0);
        }, 0)

        setOnekonetotwokCupcount(filtered_data_range_101_to_200)
        totalCups += filtered_data_range_101_to_200

        let filtered_data_range_201_to_300 = resdata.data.filtered_data_range_201_to_300.reduce((acc, val) => {
            return acc + (val.cups || 0);
        }, 0)

        setTwokonetothreekCupcount(filtered_data_range_201_to_300)
        totalCups += filtered_data_range_201_to_300

        let filtered_data_range_301_to_Above = resdata.data.filtered_data_range_301_to_Above.reduce((acc, val) => {
            return acc + (val.cups || 0);
        }, 0)

        setAbovethreekCupcount(filtered_data_range_301_to_Above)
        totalCups += filtered_data_range_301_to_Above

        setTotalCups(totalCups)

        // Price calculation


        let filtered_data_range_0_to_0Price = resdata.data.filtered_data_range_0_to_0.reduce((acc, val) => {
            let cupCost = typeof val.cupCost === 'string' ? parseFloat(val.cupCost) : val.cupCost;
            cupCost = isNaN(cupCost) || cupCost === undefined ? 0 : cupCost;
            return acc + (val.cups * cupCost);
        }, 0)

        setZeroRangeCupPrice(filtered_data_range_0_to_0Price)
        totalAmount += filtered_data_range_0_to_0Price;


        let filtered_data_range_1_to_500Price = resdata.data.filtered_data_range_1_to_50.reduce((acc, val) => {
            let cupCost = typeof val.cupCost === 'string' ? parseFloat(val.cupCost) : val.cupCost;
            cupCost = isNaN(cupCost) || cupCost === undefined ? 0 : cupCost;
            return acc + (val.cups * cupCost);
        }, 0)

        setOnefiveHundredCupPrice(filtered_data_range_1_to_500Price)
        totalAmount += filtered_data_range_1_to_500Price;


        let filtered_data_range_501_to_1000Price = resdata.data.filtered_data_range_51_to_100.reduce((acc, val) => {
            let cupCost = typeof val.cupCost === 'string' ? parseFloat(val.cupCost) : val.cupCost;
            cupCost = isNaN(cupCost) || cupCost === undefined ? 0 : cupCost;
            return acc + (val.cups * cupCost);
        }, 0)

        setFivehundredonetoonekcupPrice(filtered_data_range_501_to_1000Price)
        totalAmount += filtered_data_range_501_to_1000Price;


        let filtered_data_range_1001_to_2000Price = resdata.data.filtered_data_range_101_to_200.reduce((acc, val) => {
            let cupCost = typeof val.cupCost === 'string' ? parseFloat(val.cupCost) : val.cupCost;
            cupCost = isNaN(cupCost) || cupCost === undefined ? 0 : cupCost;
            return acc + (val.cups * cupCost);
        }, 0)

        setOnekonetotwokCupPrice(filtered_data_range_1001_to_2000Price)
        totalAmount += filtered_data_range_1001_to_2000Price;


        let filtered_data_range_2001_to_3000Price = resdata.data.filtered_data_range_201_to_300.reduce((acc, val) => {
            let cupCost = typeof val.cupCost === 'string' ? parseFloat(val.cupCost) : val.cupCost;
            cupCost = isNaN(cupCost) || cupCost === undefined ? 0 : cupCost;
            return acc + (val.cups * cupCost);
        }, 0)

        setTwokonetothreekCupPrice(filtered_data_range_2001_to_3000Price)
        totalAmount += filtered_data_range_2001_to_3000Price;


        let filtered_data_range_3001_to_AbovePrice = resdata.data.filtered_data_range_301_to_Above.reduce((acc, val) => {
            let cupCost = typeof val.cupCost === 'string' ? parseFloat(val.cupCost) : val.cupCost;
            cupCost = isNaN(cupCost) || cupCost === undefined ? 0 : cupCost;
            return acc + (val.cups * cupCost);
        }, 0)

        setAbovethreekCupPrice(filtered_data_range_3001_to_AbovePrice)
        totalAmount += filtered_data_range_3001_to_AbovePrice;

        setTotalAmount(totalAmount)

        resdata.data.ranges.reverse()
        const labelsArr = resdata?.data?.ranges.map((x) => { return x.count })
        const valuesArr = resdata?.data?.ranges.map((x) => { return x.title })

        setLabelsData(labelsArr)
        setValuesData(valuesArr)

        setLoading(false)
    }

    const getRealtimeUpdate = async (data) => {
        // data.userType = initialValue.userType
        const resdata = await apiServices.realtimecupdaywiseconsumption(data);
        console.log(resdata?.data?.ranges);
        console.log(resdata?.data?.ranges);
        // to set machine count numbers by range
        await resdata.data.ranges.forEach((obj) => {
            if (obj.title == "0 Cups") {
                setZeroRangeMachinecount(obj.count)
            } else if (obj.title == "1 to 50 Cups") {
                setOnefiveHundredmachinecount(obj.count)
            } else if (obj.title == "51 to 100 Cups") {
                setFivehundredonetoonekmachinecount(obj.count)
            } else if (obj.title == "101 to 200 Cups") {
                setOnekonetotwokmachinecount(obj.count)
            } else if (obj.title == "201 to 300 Cups") {
                setTwokonetothreekmachinecount(obj.count)
            } else if (obj.title == "Above 300 Cups") {
                setAbovethreekmachinecount(obj.count)
            }
        })

        // to set cupcount for machine count by range

        let filtered_data_range_0_to_0 = resdata.data.filtered_data_range_0_to_0.reduce((acc, val) => {
            return acc + (val.cups || 0);
        }, 0)

        setZeroRangeCupcount(filtered_data_range_0_to_0)

        let filtered_data_range_1_to_50 = resdata.data.filtered_data_range_1_to_50.reduce((acc, val) => {
            return acc + (val.cups || 0);
        }, 0)

        setOnefiveHundredCupcount(filtered_data_range_1_to_50)

        let filtered_data_range_51_to_100 = resdata.data.filtered_data_range_51_to_100.reduce((acc, val) => {
            return acc + (val.cups || 0);
        }, 0)

        setFivehundredonetoonekcupcount(filtered_data_range_51_to_100)

        let filtered_data_range_101_to_200 = resdata.data.filtered_data_range_101_to_200.reduce((acc, val) => {
            return acc + (val.cups || 0);
        }, 0)

        setOnekonetotwokCupcount(filtered_data_range_101_to_200)

        let filtered_data_range_201_to_300 = resdata.data.filtered_data_range_201_to_300.reduce((acc, val) => {
            return acc + (val.cups || 0);
        }, 0)

        setTwokonetothreekCupcount(filtered_data_range_201_to_300)

        let filtered_data_range_301_to_Above = resdata.data.filtered_data_range_3001_to_Above.reduce((acc, val) => {
            return acc + (val.cups || 0);
        }, 0)

        setAbovethreekCupcount(filtered_data_range_301_to_Above)
    }

    const barData = barDataForBarChart(valuesData, labelsData, 'CVES Count', colorData)
    const pieChartData = DataForpieChart(valuesData, labelsData, 'CVES Count', colorData)
    const barOptionsForBarChart1 = (xLabel, yLabel) => {

        return {
            plugins: {
                legend: {
                    // labels: {
                    //     color: '#A0A7B5'
                    // }
                    display: false
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: 'rgba(160, 167, 181, .3)',
                    },
                    title: {
                        display: true,
                        text: xLabel
                    }
                },

                y: {
                    ticks: {
                        color: '#495057',
                        precision: 0
                    },
                    grid: {
                        color: 'rgba(160, 167, 181, .3)',
                    },
                    title: {
                        display: true,
                        text: yLabel
                    }
                }
            },
            onClick: function (e, clickedElement) {
                getBarData(clickedElement)
                // console.log(clickedElement);
                // console.log(e)
                // debugger;
                // var activePointLabel = this.getElementsAtEvent(e)[0]._model.label;
                // console.log(activePointLabel);
                // alert('hello');
            }
        };
    }

    const barOptionsForBarChart2 = (xLabel, yLabel) => {

        return {
            plugins: {
                legend: {
                    labels: {
                        usePointStyle: true
                    }
                }
            },
            scales: {
                x: {
                    // ticks: {
                    //     color: '#495057'
                    // },
                    // grid: {
                    //     color: 'rgba(160, 167, 181, .3)',
                    // },
                    title: {
                        display: true,
                        // text: xLabel
                    }
                },

                y: {
                    // ticks: {
                    //     color: '#495057',
                    //     precision: 0
                    // },
                    // grid: {
                    //     color: 'rgba(160, 167, 181, .3)',
                    // },
                    title: {
                        // display: true,
                        text: yLabel
                    }
                }
            },
            onClick: function (e, clickedElement) {
                getBarData(clickedElement)
                // console.log(clickedElement);
                // console.log(e)
                // debugger;
                // var activePointLabel = this.getElementsAtEvent(e)[0]._model.label;
                // console.log(activePointLabel);
                // alert('hello');
            }
        };
    }

    const barOptions = barOptionsForBarChart1('Consumption Range', 'CVES Count')
    const pieOptions = barOptionsForBarChart2('Consumption Range', 'CVES Count')


    const getBarData = (data) => {
        if (data.length > 0) {
            setDetailedDialog(true)
            const chartIndex = chartMasterData[data[0].index];
            console.log(chartIndex);
            getChartReport(chartIndex)
        }
    }

    const getChartReport = async (chartIndex) => {
        if (chartIndex.title == 'Above 300 Cups') {
            const reportData = masterData.filtered_data_range_301_to_Above.map((x) => {
                return { ...x, subscription_startDate: x.subscription_startDate ? new Date(x.subscription_startDate).toLocaleString('en-IN') : '-' }
            })
            setDetailedData(reportData)
        } else if (chartIndex.title == '201 to 300 Cups') {
            const reportData = masterData.filtered_data_range_201_to_300.map((x) => {
                return { ...x, subscription_startDate: x.subscription_startDate ? new Date(x.subscription_startDate).toLocaleString('en-IN') : '-' }
            })
            setDetailedData(reportData)
        } else if (chartIndex.title == '101 to 200 Cups') {
            const reportData = masterData.filtered_data_range_101_to_200.map((x) => {
                return { ...x, subscription_startDate: x.subscription_startDate ? new Date(x.subscription_startDate).toLocaleString('en-IN') : '-' }
            })
            setDetailedData(reportData)
        } else if (chartIndex.title == '51 to 100 Cups') {
            const reportData = masterData.filtered_data_range_51_to_100.map((x) => {
                return { ...x, subscription_startDate: x.subscription_startDate ? new Date(x.subscription_startDate).toLocaleString('en-IN') : '-' }
            })
            setDetailedData(reportData)
        } else if (chartIndex.title == '1 to 50 Cups') {
            const reportData = masterData.filtered_data_range_1_to_50.map((x) => {
                return { ...x, subscription_startDate: x.subscription_startDate ? new Date(x.subscription_startDate).toLocaleString('en-IN') : '-' }
            })
            setDetailedData(reportData)
        } else if (chartIndex.title == '0 Cups') {
            const reportData = masterData.filtered_data_range_0_to_0.map((x) => {
                return { ...x, subscription_startDate: x.subscription_startDate ? new Date(x.subscription_startDate).toLocaleString('en-IN') : '-' }
            })
            setDetailedData(reportData)
        }
    }

    const changeDuration = (e) => {
        // setLoading(true)
        setBtnVisibility(true)
        setSelectedDuration(e.target.value)

        const currdate = new Date();
        const nowDate = new Date().toISOString();

        switch (e.target.value.code) {
            case 'this_month':

                const lastOneStart = new Date(currdate.getFullYear(), currdate.getMonth() - 1, 1).setHours(0, 0, 0, 0);
                const lastStart1 = new Date(lastOneStart).toISOString();
                const dateLastMonth = { startDate: lastStart1, endDate: nowDate };
                setSelectedDate({ date: dateLastMonth })
                break;
            case 'last_three_month':
                const lastMonthStart = new Date(currdate.getFullYear(), currdate.getMonth() - 3, 1).setHours(0, 0, 0, 0);
                const lastStart = new Date(lastMonthStart).toISOString();
                const date = { startDate: lastStart, endDate: nowDate };
                setSelectedDate({ date: date })
                break;
            case 'last_six_month':
                const lastsix = new Date(currdate.getFullYear(), currdate.getMonth() - 6, 1).setHours(0, 0, 0, 0);
                const lastSixStart = new Date(lastsix).toISOString();
                const sixDate = { startDate: lastSixStart, endDate: nowDate };
                setSelectedDate({ date: sixDate })
                break;
            case 'last_twelve_months':

                const lastTwelve = new Date(currdate.getFullYear(), currdate.getMonth() - 12, 1).setHours(0, 0, 0, 0);
                const lastTwelveStart = new Date(lastTwelve).toISOString();
                const twelveDate = { startDate: lastTwelveStart, endDate: nowDate };
                setSelectedDate({ date: twelveDate })
                break;
            case 'custom':
                setCustomFilterDialog(true)
                break;
            default:
                setSelectedDate(null)
        }
    }

    const changeBrand = (e) => {

        if (e.value?.length > 0) {
            setSelectedBrand(e.value)
            setBtnVisibility(true)
        }
    }



    const filterData = () => {
        setAllDataLoading()
        setLoading(true)
        setBtnVisibility(false)
        const filteredData = selectedbrand.map((x) => { return x.code })

        const startt = new Date(filteredDate.startDate).setHours(0, 0, 0, 0);
        const endd = new Date(filteredDate.endDate).setHours(23, 59, 59, 0);
        let cust_start = new Date(startt).toISOString();
        let cust_end = new Date(endd).toISOString();
        var date = { startDate: cust_start, endDate: cust_end };

        getReport({ brand: filteredData, date: date })
    }

    const leftToolbarTemplate = () => (
        <>
            <div className="p-inputgroup p-m-1">
                <Button className='custom-group-btn' label="BRAND" />
                <MultiSelect name='brand' value={selectedbrand} onChange={changeBrand} options={brandsOptions} optionLabel="name" display="chip" placeholder="Select Brand" maxSelectedLabels={3} filter />
            </div>
        </>
    )

    const rightToolbarTemplate = () => (
        <>
            {filteredDate &&
                <div className="p-inputgroup p-m-1" style={{ minWidth: '300px', maxWidth: '300px', fontSize: '1.3rem' }}>
                    <b>Date : {` ${new Date(filteredDate?.startDate).getDate()}/${new Date(filteredDate?.startDate)?.getMonth() + 1}/${new Date(filteredDate?.startDate)?.getFullYear()} - ${new Date(filteredDate?.endDate).getDate()}/${new Date(filteredDate?.endDate)?.getMonth() + 1}/${new Date(filteredDate?.endDate)?.getFullYear()} `}  </b>
                </div>
            }
            <div className="p-inputgroup p-m-1">
                <Button className='custom-group-btn' label="DURATION" />
                <Dropdown value={selectedDuration} options={durationOptions} onChange={changeDuration} optionLabel="name" placeholder="Select Duration" filter className="p-m-0 multiselect-custom" />
            </div>

            <div className="p-inputgroup p-m-1">
                {btnVisibility && <Button icon="pi pi-filter" label='Filter' className="custom-group-btn p-button p-my-2 p-mx-2" onClick={filterData} />}
            </div>

        </>
    )

    const getTest = () => {
        // Array for month names
        var theMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
        // Start from January 2020
        var now = new Date("2020-01-01");
    
        const arrOfValues = [];
        
        // Loop for 60 months (5 years)
        for (var i = 0; i < 63; i++) {
            var future = new Date(now.getFullYear(), now.getMonth() + i, 1); // Set future date
            var month = theMonths[future.getMonth()];  // Get month name
            var year = future.getFullYear();           // Use the future year as is
            arrOfValues.push({ name: `${month} ${year}`, code: `${month}-${year}` }); // Add to array
        }
    
        // Reverse array if needed (to have the most recent month first)
        const customMonths = arrOfValues.reverse();  
        
        // Map through and add an index property to each object
        const lastArr = customMonths.map((x, i) => {
            return { ...x, index: i };
        });
    
        // Set the data (likely for a dropdown or display)
        setLastMonthsListDropDown(lastArr);
    }
    
    const hideDetailed = () => { setDetailedDialog(false) }
    const sensorFooter = commonCloseDialogFooter('Close', hideDetailed)

    const exportCSV = () => { dt.current.exportCSV(); }

    const header = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <h6 className='p-mt-2'>Consumption Details</h6>
            </span >
            <span className="p-input-icon-right" >
                <div className="p-grid">
                    <div className='p-col-10'></div>
                    <div className='p-col-2'> <Button icon="pi pi-download" className='p-ml-100 custom-group-btn p-button-rounded' onClick={exportCSV} /></div>
                </div>
            </span>
        </div>
    );
    const customFooter = () => (
        <>
            <button className="p-button p-button p-button-danger" onClick={hideCustomDialog}><span className="p-button-icon p-c pi pi-times p-button-icon-left"></span><span className="p-button-label p-c">Cancel</span><span className="p-ink"></span></button>
            {
                customType && <button className="p-button p-button-success" type="submit" form="custom-form" ><span className="p-button-icon p-c pi pi-filter p-button-icon-left"></span><span className="p-button-label p-c">Click to Filter</span><span className="p-ink"></span></button>
            }
        </>
    )
    const hideCustomDialog = () => {
        setCustomFilterDialog(false);
        setSelectedDuration(null)
        // setTimeselectValue({name:'All',code:'all'});
    }

    const handleSubmit = async (e) => {
        setLoading(true)
        setAllDataLoading()
        setCustomFilterDialog(false)
        // setTimeselectValue({name:'Custom',code:'custom'});
        e.preventDefault()

        if (customType?.code == 'datewise') {
            const startt = new Date(e.target.start_date.value).setHours(0, 0, 0, 0);
            const endd = new Date(e.target.end_date.value).setHours(23, 59, 59, 0);
            let cust_start = new Date(startt).toISOString();
            let cust_end = new Date(endd).toISOString();
            var date = { startDate: cust_start, endDate: cust_end };
        }else if(customType?.code == 'datewisesuper'){
            let initialDate = e.target.start_date.value;
            const start_date = new Date(e.target.start_date.value).setHours(0, 0, 0, 0);
            const startDate = new Date(start_date);

            // const firstDayOfMonth = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
            
            // const lastDayOfMonth = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
            // const custom_start = new Date(firstDayOfMonth.getFullYear(), firstDayOfMonth.getMonth(), firstDayOfMonth.getDate(), 0, 0, 0).toISOString();
            // const custom_end = new Date(lastDayOfMonth.getFullYear(), lastDayOfMonth.getMonth(), lastDayOfMonth.getDate(), 23, 59, 59).toISOString();
            let initialEndDate = new Date(new Date(start_date).getFullYear(), new Date(start_date).getMonth(), new Date(start_date).getDate(), 23, 59, 59).toISOString();
            // Construct the date object
            var date = { startDate: startDate, endDate: initialEndDate };
        }

        setBtnVisibility(false)
        const filteredData = selectedbrand.map((x) => { return x.code })
        getReport({ brand: filteredData, date: date })
        setFilteredDate(date)
        setSelectedDuration(null)
        setCustomType(false)
        setSelectedDuration(null)
    }

    const setAllDataLoading = () => {
        setZeroRangeMachinecount(0)
        setOnefiveHundredmachinecount(0)
        setFivehundredonetoonekmachinecount(0)
        setOnekonetotwokmachinecount(0)
        setTwokonetothreekmachinecount(0)
        setAbovethreekmachinecount(0)
        setZeroRangeCupcount(0)
        setOnefiveHundredCupcount(0)
        setFivehundredonetoonekcupcount(0)
        setOnekonetotwokCupcount(0)
        setTwokonetothreekCupcount(0)
        setAbovethreekCupcount(0)
        // price states
        setZeroRangeCupPrice(0)
        setOnefiveHundredCupPrice(0)
        setFivehundredonetoonekcupPrice(0)
        setOnekonetotwokCupPrice(0)
        setTwokonetothreekCupPrice(0)
        setAbovethreekCupPrice(0)
        setTotalMachines(0)
        setTotalCups(0)
        setTotalAmount(0)    
    }

    return (
        <>

            <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
            <div className="p-grid">
                <div className="p-col-12 p-md-12">
                    <div className="card widget-performance">
                        <div className="header">
                            <h4>Total Consumption Data</h4>
                        </div>
                        <div className="content p-grid">
                            <div className="p-col-12 p-md-12">
                                <span style={{ fontWeight: 'bold', backgroundColor: "#2e4f5e", color: "#fff", padding: "5px", display: 'block', marginBottom: "-10px", marginBottom: "-10px" }}>Total Consumption Data</span>
                                <div className="person-item" style={{ marginBottom: "-20px", borderBottom: '2px solid grey', borderRadius: '10px', marginBottom: "0.5px", padding: "20px" }}>
                                    <Avatar image="assets/layout/images/new-login-img_7201.png" className="p-mr-2 p-overlay-badge" shape="circle" style={{ width: "30px", height: "30px" }}></Avatar>
                                    <span><b> {totalMachines}</b> </span>
                                    <Avatar image="assets/layout/images/bev-options.png" className="p-mr-2 p-overlay-badge" shape="circle" style={{ width: "30px", height: "30px", marginLeft: '20px' }}></Avatar>
                                    <span><b>{totalCups} </b> </span>
                                    <Avatar image="assets/layout/images/rupee-logo.png" className="p-mr-2 p-overlay-badge" shape="circle" style={{ width: "30px", height: "30px", marginLeft: '20px' }}></Avatar>
                                    <span><b>{parseInt(totalAmount)} </b> </span>
                                </div>
                            </div>
                        </div>
                        <div className="header">
                            <h4>Performance By Range</h4>
                        </div>
                        <div className="content p-grid">
                            <div className="p-col-12 p-md-4">
                                <span style={{ fontWeight: 'bold', backgroundColor: "#2e4f5e", color: "#fff", padding: "5px", display: 'block', marginBottom: "-10px", marginBottom: "-10px" }}>0 Cups</span>
                                <div className="person-item" style={{ marginBottom: "-20px", borderBottom: '2px solid grey', borderRadius: '10px', marginBottom: "0.5px", padding: "20px" }}>
                                    <Avatar image="assets/layout/images/new-login-img_7201.png" className="p-mr-2 p-overlay-badge" shape="circle" style={{ width: "30px", height: "30px" }}></Avatar>
                                    <span><b> {zerorangemachinecount}</b> </span>
                                    <Avatar image="assets/layout/images/bev-options.png" className="p-mr-2 p-overlay-badge" shape="circle" style={{ width: "30px", height: "30px", marginLeft: '20px' }}></Avatar>
                                    <span><b>{zerorangecupcount} </b> </span>
                                    <Avatar image="assets/layout/images/rupee-logo.png" className="p-mr-2 p-overlay-badge" shape="circle" style={{ width: "30px", height: "30px", marginLeft: '20px' }}></Avatar>
                                    <span><b>{parseInt(zerorangecupPrice)} </b> </span>
                                </div>
                            </div>

                            <div className="p-col-12 p-md-4">
                                <span style={{ fontWeight: 'bold', backgroundColor: "#2e4f5e", color: "#fff", padding: "5px", display: 'block', marginBottom: "-10px", marginBottom: "-10px" }}>1 to 50 Cups</span>
                                <div className="person-item" style={{ marginBottom: "-20px", borderBottom: '2px solid grey', borderRadius: '10px', marginBottom: "0.5px", padding: "20px" }}>
                                    <Avatar image="assets/layout/images/new-login-img_7201.png" className="p-mr-2 p-overlay-badge" shape="circle" style={{ width: "30px", height: "30px" }}></Avatar>
                                    <span><b> {onefivehundredmachinecount}</b> </span>
                                    <Avatar image="assets/layout/images/bev-options.png" className="p-mr-2 p-overlay-badge" shape="circle" style={{ width: "30px", height: "30px", marginLeft: '20px' }}></Avatar>
                                    <span><b>{onefivehundredcupcount} </b> </span>
                                    <Avatar image="assets/layout/images/rupee-logo.png" className="p-mr-2 p-overlay-badge" shape="circle" style={{ width: "30px", height: "30px", marginLeft: '20px' }}></Avatar>
                                    <span><b>{parseInt(onefivehundredcupPrice)} </b> </span>
                                </div>
                            </div>

                            <div className="p-col-12 p-md-4">
                                <span style={{ fontWeight: 'bold', backgroundColor: "#2e4f5e", color: "#fff", padding: "5px", display: 'block', marginBottom: "-10px" }}>51 to 100 Cups</span>
                                <div className="person-item" style={{ marginBottom: "-20px", borderBottom: '2px solid grey', borderRadius: '10px', marginBottom: "0.5px", padding: "20px" }}>
                                    <Avatar image="assets/layout/images/new-login-img_7201.png" className="p-mr-2 p-overlay-badge" shape="circle" style={{ width: "30px", height: "30px" }}></Avatar>
                                    <span><b> {fivehundredonetoonekmachinecount}</b> </span>
                                    <Avatar image="assets/layout/images/bev-options.png" className="p-mr-2 p-overlay-badge" shape="circle" style={{ width: "30px", height: "30px", marginLeft: '20px' }}></Avatar>
                                    <span><b> {fivehundredonetoonekcupcount}</b> </span>
                                    <Avatar image="assets/layout/images/rupee-logo.png" className="p-mr-2 p-overlay-badge" shape="circle" style={{ width: "30px", height: "30px", marginLeft: '20px' }}></Avatar>
                                    <span><b> {parseInt(fivehundredonetoonekcupPrice)}</b> </span>
                                </div>
                            </div>

                            <div className="p-col-12 p-md-4">
                                <span style={{ fontWeight: 'bold', backgroundColor: "#2e4f5e", color: "#fff", padding: "5px", display: 'block', marginBottom: "-10px" }}>101 to 200 Cups</span>
                                <div className="person-item" style={{ marginBottom: "-20px", borderBottom: '2px solid grey', borderRadius: '10px', marginBottom: "0.5px", padding: "20px" }}>
                                    <Avatar image="assets/layout/images/new-login-img_7201.png" className="p-mr-2 p-overlay-badge" shape="circle" style={{ width: "30px", height: "30px" }}></Avatar>
                                    <span><b>{onekonetotwokmachinecount} </b> </span>
                                    <Avatar image="assets/layout/images/bev-options.png" className="p-mr-2 p-overlay-badge" shape="circle" style={{ width: "30px", height: "30px", marginLeft: '20px' }}></Avatar>
                                    <span><b> {onekonetotwokcupcount}</b> </span>
                                    <Avatar image="assets/layout/images/rupee-logo.png" className="p-mr-2 p-overlay-badge" shape="circle" style={{ width: "30px", height: "30px", marginLeft: '20px' }}></Avatar>
                                    <span><b> {parseInt(onekonetotwokcupPrice)}</b> </span>
                                </div>
                            </div>

                            <div className="p-col-12 p-md-4">
                                <span style={{ fontWeight: 'bold', backgroundColor: "#2e4f5e", color: "#fff", padding: "5px", display: 'block', marginBottom: "-10px" }}>201 to 300 Cups</span>
                                <div className="person-item" style={{ marginBottom: "-20px", borderBottom: '2px solid grey', borderRadius: '10px', marginBottom: "0.5px", padding: "20px" }}>
                                    <Avatar image="assets/layout/images/new-login-img_7201.png" className="p-mr-2 p-overlay-badge" shape="circle" style={{ width: "30px", height: "30px" }}></Avatar>
                                    <span><b>{twokonetothreekmachinecount} </b> </span>
                                    <Avatar image="assets/layout/images/bev-options.png" className="p-mr-2 p-overlay-badge" shape="circle" style={{ width: "30px", height: "30px", marginLeft: '20px' }}></Avatar>
                                    <span><b>{twokonetothreekcupcount} </b> </span>
                                    <Avatar image="assets/layout/images/rupee-logo.png" className="p-mr-2 p-overlay-badge" shape="circle" style={{ width: "30px", height: "30px", marginLeft: '20px' }}></Avatar>
                                    <span><b>{parseInt(twokonetothreekcupPrice)} </b> </span>
                                </div>
                            </div>

                            <div className="p-col-12 p-md-4">
                                <span style={{ fontWeight: 'bold', backgroundColor: "#2e4f5e", color: "#fff", padding: "5px", display: 'block', marginBottom: "-10px" }}>Above 300 Cups</span>
                                <div className="person-item" style={{ marginBottom: "-20px", borderBottom: '2px solid grey', borderRadius: '10px', marginBottom: "0.5px", padding: "20px" }}>
                                    <Avatar image="assets/layout/images/new-login-img_7201.png" className="p-mr-2 p-overlay-badge" shape="circle" style={{ width: "30px", height: "30px" }}></Avatar>
                                    <span><b>{abovethreekmachinecount} </b> </span>
                                    <Avatar image="assets/layout/images/bev-options.png" className="p-mr-2 p-overlay-badge" shape="circle" style={{ width: "30px", height: "30px", marginLeft: '20px' }}></Avatar>
                                    <span><b> {abovethreekcupcount}</b> </span>
                                    <Avatar image="assets/layout/images/rupee-logo.png" className="p-mr-2 p-overlay-badge" shape="circle" style={{ width: "30px", height: "30px", marginLeft: '20px' }}></Avatar>
                                    <span><b> {parseInt(abovethreekcupPrice)}</b> </span>
                                </div>
                            </div>

                            {/* </div> */}
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-md-8">
                    <div className="card">
                        {
                            loading ?
                                <>
                                    <div className="spinner-div">
                                        <center><ProgressSpinner strokeWidth="7" animationDuration='4s' /></center>
                                    </div>
                                </>
                                :
                                <>
                                    <h3>Cup Consumption Overview</h3>
                                    <Chart type="bar" data={barData} options={barOptions} />
                                </>
                        }

                    </div>
                </div>
                <div className="p-col-12 p-md-4">
                    <div className="card">
                        {
                            loading ?
                                <>
                                    <div className="spinner-div">
                                        <center><ProgressSpinner strokeWidth="7" animationDuration='4s' /></center>
                                    </div>
                                </>
                                :
                                <>
                                    <h3>Cup Consumption Overview</h3>
                                    <Chart type="pie" data={pieChartData} options={pieOptions} />
                                </>
                        }

                    </div>
                </div>
            </div>

            <DataTable ref={dtReport} value={chartMasterData} dataKey="id" exportFilename={`MonthWise Installations Report`} style={{ display: 'none' }}>
                <Column field="month" header="Month" body={CommonBodyForTable}></Column>
                <Column field="count" header="Installation Count" body={CommonBodyForTable}></Column>
            </DataTable>

            <Dialog className="p-fluid" header={`Detailed Report`} visible={detailedDialog} style={{ width: '50vw' }} footer={sensorFooter} onHide={hideDetailed} maximizable={true} blockScroll={true}>
                <div className="p-grid crud-demo">
                    <div className="p-col-12">
                        <div className="card">
                            <DataTable ref={dt} value={detailedData}
                                dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                                emptyMessage="No data found." globalFilter={globalFilter} header={header} exportFilename={`Consumption Details (Day Wise) ${new Date(filteredDate?.startDate).getDate()}/${new Date(filteredDate?.startDate)?.getMonth() + 1}/${new Date(filteredDate?.startDate)?.getFullYear()} - ${new Date(filteredDate?.endDate).getDate()}/${new Date(filteredDate?.endDate)?.getMonth() + 1}/${new Date(filteredDate?.endDate)?.getFullYear()}`} >
                                <Column field="srid" header="Sr.No." body={srBodyTemplate} style={{ width: '4rem' }} exportable={false}></Column>
                                <Column field="id" header="CVES ID" body={CommonBodyForTable} sortable ></Column>
                                <Column field="siteName" header="Account Name" body={CommonBodyForTable} sortable ></Column>
                                <Column field="cupCost" header="Cup Cost" body={CommonBodyForTable} sortable ></Column>
                                <Column field="cups" header="Cups" body={CommonBodyForTable} sortable ></Column>
                                <Column field="Total Cost" header="Total Amount" body={totalcostTemplate} sortable ></Column>
                                {/* <Column field="subid" header="Sub Id" body={CommonBodyForTable} sortable ></Column> */}
                                <Column field="brand" header="Brand" body={CommonBodyForTable} sortable ></Column>
                                <Column field="subscription_startDate" header="Subscripttion Date" body={CommonBodyForTable} sortable ></Column>
                                {/* <Column field="subDate" header="Subscription Date" body={CommonBodyForTable} ></Column> */}
                            </DataTable>
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog visible={customFilterDialog} style={{ width: '550px' }} header="Custom Filter" footer={customFooter} onHide={hideCustomDialog} modal className="p-fluid" maximizable={true} blockScroll={true}>
                {

                    <form onSubmit={handleSubmit} id="custom-form" className="p-fluid">
                        <div className='p-grid'>
                            <div className='p-col-12'>
                                <label htmlFor="lasttt">Select Filter Type </label>
                                <Dropdown id='lasttt' optionLabel="name" options={customWise} value={customType} onChange={(e) => { setCustomType(e.value) }} placeholder="Select Filter Type" filter className="p-mr-2 multiselect-custom " />
                            </div>

                            {
                                customType?.code == 'monthwise' &&
                                <>
                                    <div className='p-col-12'>
                                        <label htmlFor="lasttt">Select Months </label>
                                        <Dropdown id='lasttt' optionLabel="name" options={lastMonthsListDropDown} value={selectedCustomMonth} onChange={(e) => { setSelectedCustomMonth(e.value) }} placeholder="Select Last Months" filter className="p-mr-2 multiselect-custom " required />
                                    </div>
                                </>
                            }

                            {
                                customType?.code == 'datewise' &&
                                <>
                                    <div className='p-col-6'>
                                        <label htmlFor="from">From </label>
                                        <InputText id="from" type="date" name='start_date' max={new Date().toISOString().slice(0, 10)} value={customStart} onChange={(e) => { setCustomStart(e.target.value) }} required autoFocus />
                                    </div>
                                    <div className='p-col-6'>
                                        <label htmlFor="to">To</label>
                                        <InputText id="to" type="date" name='end_date' min={customStart} max={new Date().toISOString().slice(0, 10)} value={customEnd} onChange={(e) => { setCustomEnd(e.target.value) }} required />
                                    </div>
                                </>
                            }
                            {
                                customType?.code == 'datewisesuper' &&
                                <>
                                    <div className='p-col-6'>
                                        <label htmlFor="Select Date">Select Date </label>
                                        <InputText id="Select Date" type="date" name='start_date' max={new Date().toISOString().slice(0, 10)} value={customStart} onChange={(e) => { setCustomStart(e.target.value) }} required autoFocus />
                                    </div>
                                </>
                            }


                        </div>
                    </form>
                }

            </Dialog>

        </>
    )
}
