import React, {useEffect,useState} from 'react';

// Services
import ApiServices from '../../service/api/ApiServices';
const apiServices =  new ApiServices();

export const MachineInventory = () =>{


    return (
        <>
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <h1>Machine Inventory</h1>
                        <iframe height='500px' width='100%' frameborder='0' allowTransparency='true' scrolling='auto' src='https://creatorapp.zohopublic.com/parimal4/member/report-embed/CVES_Machines_Report/8V3RXjMTEv9QERkTh70Tqv3jhkeFKp7eEvKbTUWp4wfmYATaYRn4wTAN30GRBWXS1vmb4rqyJfSKA1VBqxbrXMxNqKnwkv0N2uTk'></iframe>
                    </div>
                </div>
            </div>
        </>
    )
}