import React from 'react'
import { useState, useEffect, useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Accordion } from 'primereact/accordion'
import { AccordionTab } from 'primereact/accordion'
import { TableWrapper } from '../../utilComponents/DataTableWrapper'
import { Button } from 'primereact/button'
import { srBodyTemplate, DateBodyForTable } from '../Common/CommonComponents'
import ApiServices from '../../service/api/ApiServices'
import { permissionContext } from '../../AppWrapper';
import { managementCheck } from '../../service/admin/superadmin'
import { Dialog } from "primereact/dialog";
import { CommonBodyForTable } from "../Common/CommonComponents";
import { exportReport } from "../Common/CommonFunctions";

const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

const apiServices = new ApiServices()


// ======================================================================================================
// ======================================================================================================

export const PanelOverview = ({ defaultMachine, creditData, machineAgreements, multiple = true }) => {
    const [barcodeDetailsDialog, setBarcodeDetailsDialog] = useState(false);
    const [barcodeDetails, setBarcodeDetails] = useState([])
    const [deliveredBarcodeCount, setdeliveredBarcodeCount] = useState(0)
    const [editFacData, setEditFacData] = useState({})
    const [facilityLoading, setFacilityLoading] = useState(true)

    const rolePermissions = useContext(permissionContext);
    const history = useHistory();
    const cupQuantity = 60 + (defaultMachine?.mchmode?.wtr_offset || 0)
    const [accountDetails, setAccountDetails] = useState({});

    const dt = useRef(null);
    // console.log(rolePermissions)
    const fileBodyForTable = (rowData, props) => (<> <span className="p-column-title">{props.header}</span> {rowData.siteName} </>)
    const actionBodyTemplate = (rowData) => (
        <>
            <span className="p-column-title">Actions</span>
            <Button icon="pi pi-eye" title='View Agreement' label='View Agreement' className="p-button-rounded p-button-primary" aria-label="View Order" onClick={() => { showAgreements(rowData) }} />
        </>
    )

    const viewEmployee = () => {
        history.push('./employees');
        window.location.reload();
    }

    const showAgreements = async (rowData) => {
        // console.log("showAgreements", rowData);
        const response = await apiServices.sendNewAgreement(rowData);
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const downloadURL = window.URL.createObjectURL(blob);
        window.open(downloadURL, '_blank');
    }

    const barcodeDetailsFooter = (<><Button label="Close" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={() => { setBarcodeDetailsDialog(false) }} /></>)

    useEffect(async () => {
        if (defaultMachine.customer) {
            const data = {
                "kam_userid": defaultMachine?.superman?.accountmanager,
                "userid": defaultMachine.customer,
                "facid": defaultMachine?.subscription?.facilitydata?.facid
            }
            console.log("data", data);
            const response = await apiServices.cves_userInfo(data);
            if (response?.data?.success) {
                setAccountDetails({
                    account_manager_data: response?.data?.account_manager_data,
                    facility_data: response?.data?.facility_data,
                    user_data: response?.data?.user_data
                })
            }
        }

    }, [defaultMachine])

    /**
  * barcode details dialog
  * @param {*} rowData // selected row data
  */
    const openBarcodeDetailsDialog = (rowData) => {
        setBarcodeDetails([])
        setEditFacData(rowData)
        getBarcodeDetails(rowData)
        setBarcodeDetailsDialog(true)
    }

    /**
     * funtion will provide barcode list assigned to machine
     * @param {*} data 
     */
    const getBarcodeDetails = async (data) => {
        setdeliveredBarcodeCount(0)
        setFacilityLoading(true)
        let tempArr = []
        const res = await apiServices.inventoryFacilityBarcodeDetails({ facid: data.facid });
        if (res.data.data.length > 0) {
            res.data.data[0].barcodes.map((item, index) => {
                let tempObj = {}
                tempObj.barcode = item
                tempObj.stage = res.data.data[0].stage
                tempArr.push(tempObj)
            })
        }
        setBarcodeDetails(tempArr)
        if (res.data && res.data.data && res.data.data[0] && res.data.data[0].deliveredCount) {
            setdeliveredBarcodeCount(res.data.data[0].deliveredCount)
        }
        setFacilityLoading(false)

    }

    const header1 = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <h6 className='p-mt-2'>Total Barcode Delivered : {deliveredBarcodeCount}</h6>
            </span >
            <span className="p-input-icon-right" >
                <div className="p-grid">
                    <div className='p-col-10'></div>
                    {/* <div className='p-col-2'> <Button icon="pi pi-download" className='p-ml-100 custom-group-btn p-button-rounded' onClick={exportCSV} /></div> */}
                </div>
            </span>
        </div>
    );

    const exportCSV = () => { exportReport(dt) }


    return (
        <>
            {initialValue?.userType != 'invenstor' && <div className="card">
                <Accordion multiple={multiple} activeIndex={[0, 1, 2, 3, 4, 5, 6]}>
                    <AccordionTab header="Account Details">
                        <div className='p-grid'>
                            <div className='p-col-12 p-md-4'>
                                <b><i className="pi pi-tag" style={{ fontSize: '1rem' }}></i> Account ID :</b> {accountDetails?.user_data?.Customer_Account_ID}
                            </div>
                            <div className='p-col-12 p-md-4'>
                                <b><i className="pi pi-user" style={{ fontSize: '1rem' }}></i> Account Name :</b> {accountDetails?.user_data?.company_name || defaultMachine?.siteName}
                            </div>
                            <div className='p-col-12 p-md-4'>
                                <b><i className="pi pi-tag" style={{ fontSize: '1rem' }}></i> Date of Activation :</b> {defaultMachine?.subscription?.startDate ? new Date(defaultMachine?.subscription?.startDate).toLocaleString() : ''}
                            </div>
                            <div className='p-col-12 p-md-4'>
                                <b><i className="pi pi-map-marker" style={{ fontSize: '1rem' }}></i> Customer Number  :</b> {accountDetails?.user_data?.cno}
                            </div>
                            <div className='p-col-12 p-md-4'>
                                <b><i className="pi pi-user" style={{ fontSize: '1rem' }}></i> Facility Name :</b> {accountDetails?.facility_data?.facname}
                            </div>
                            <div className='p-col-12 p-md-4'>
                                <b><i className="pi pi-map-marker" style={{ fontSize: '1rem' }}></i> Facility Address :</b> {accountDetails?.facility_data?.address?.address}
                            </div>
                            <div className='p-col-12 p-md-4'>
                                <b><i className="pi pi-map-marker" style={{ fontSize: '1rem' }}></i> Facility City :</b> {accountDetails?.facility_data?.address?.city?.name}
                            </div>
                            <div className='p-col-12 p-md-4'>
                                <b><i className="pi pi-map-marker" style={{ fontSize: '1rem' }}></i> Facility State :</b> {accountDetails?.facility_data?.address?.state?.name}
                            </div>
                            <div className='p-col-12 p-md-4'>
                                <b><i className="pi pi-map-marker" style={{ fontSize: '1rem' }}></i> Facility Pin Code :</b> {accountDetails?.facility_data?.address?.pincode}
                            </div>
                            <hr />
                            <div className='p-col-12 p-md-4'>
                                <b><i className="pi pi-money-bill" style={{ fontSize: '1rem' }}></i> Cup Cost : ₹ </b> {defaultMachine?.subscription?.package?.halfcup}
                            </div>
                            <div className='p-col-12 p-md-4'>
                                <b><i className="pi pi-sort-amount-up" style={{ fontSize: '1rem' }}></i> Cup Quantity: </b> {cupQuantity} (ML)
                            </div>
                            <div className='p-col-12 p-md-4'>
                                <b><i className="pi pi-wallet" style={{ fontSize: '1rem' }}></i> Assigned Barcodes: </b> <Button icon="pi pi-eye" title='Make Payment' className="p-button-rounded p-button-primary" style={{ marginLeft: '1rem' }} onClick={() => { openBarcodeDetailsDialog(accountDetails?.facility_data) }} />
                            </div>

                        </div>
                    </AccordionTab>
                    {defaultMachine?.subscription?.package?.site === 'permanent' && !managementCheck() && <  AccordionTab header="Billing Details">
                        <div className='p-grid'>
                            <div className='p-col-4'>
                                <table>
                                    <tbody>
                                        <tr><td> <b> MAINTENANCE (INR) :</b>  </td></tr>
                                        <tr>
                                            <td className='p-pt-2'> <b><i className="pi pi-calendar" style={{ fontSize: '1rem' }}></i> Plan :</b>   </td>
                                            <td className='p-pt-2'> {defaultMachine?.subscription?.package?.maintenance?.packageName ? defaultMachine?.subscription?.package?.maintenance?.packageName : 'No Plan'}</td>
                                        </tr>
                                        <tr>
                                            <td className='p-pt-2'> <b><i className="pi pi-money-bill" style={{ fontSize: '1rem' }}></i> Amount :</b>   </td>
                                            <td className='p-pt-2'> {defaultMachine?.subscription?.package?.maintenance?.cost ? defaultMachine?.subscription?.package?.maintenance?.cost : '0'} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='p-col-4'>
                                <table>
                                    <tbody>
                                        <tr><td> <b> DEPOSIT (INR) :</b></td></tr>
                                        <tr>
                                            <td className='p-pt-2'> <b><i className="pi pi-calendar" style={{ fontSize: '1rem' }}></i> Plan :</b>   </td>
                                            <td className='p-pt-2'> {defaultMachine?.subscription?.package?.deposit ? defaultMachine?.subscription?.package?.deposit : 'No Plan'}</td>
                                        </tr>
                                        <tr>
                                            <td className='p-pt-2'> <b><i className="pi pi-money-bill" style={{ fontSize: '1rem' }}></i> Amount :</b>   </td>
                                            <td className='p-pt-2'> {defaultMachine?.subscription?.package?.depositamt ? defaultMachine?.subscription?.package?.depositamt : '0'} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className='p-col-4'>
                                <table>
                                    <tbody>
                                        <tr><td> <b> CREDIT LIMIT (INR) :</b></td></tr>
                                        <tr>
                                            <td className='p-pt-2'> <b><i className="pi pi-money-bill" style={{ fontSize: '1rem' }}></i> Amount :</b>   </td>
                                            <td className='p-pt-2'> {creditData?.Credit_Ceiling || '0'} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <hr />
                            {/* <div className='p-col-6'>
                                <table>
                                    <tbody>
                                        <tr><td style={{ fontSize: '16px' }}> <b> Cup Cost (INR) : {defaultMachine?.subscription?.package?.fullcup ? defaultMachine?.subscription?.package?.fullcup : '-'} ₹ </b></td></tr>
                                    </tbody>
                                </table>
                            </div> */}
                        </div>
                    </AccordionTab>}
                    {/* {defaultMachine?.subscription?.package?.site === 'permanent' && <AccordionTab header="Agreement Details">
                        <TableWrapper>
                            <DataTable value={machineAgreements} dataKey="id" className="datatable-responsive" emptyMessage="No Agreements found." >
                                <Column field="srid" header="Sr.No." body={srBodyTemplate} style={{ width: '8rem' }} exportable={false}></Column>
                                <Column field="siteName" header="Subscription Name" body={fileBodyForTable} style={{ overflowWrap: 'break-word' }} sortable ></Column>
                                <Column field="createdAt" header="Uploaded At" body={DateBodyForTable} style={{ overflowWrap: 'break-word' }} sortable ></Column>
                                <Column header="Actions" body={actionBodyTemplate} exportable={false}></Column>
                            </DataTable>
                        </TableWrapper>
                    </AccordionTab>} */}
                    {defaultMachine?.network_info && <AccordionTab header="Network Details">
                        <div className='p-grid'>
                            <div className='p-col-12 p-md-3'>
                                <b><i className="pi pi-wifi" style={{ fontSize: '1rem' }}></i> Network Type :</b> {`${defaultMachine?.network_info?.Operator} ${defaultMachine?.network_info?.SIM_Category} ` || 'NA'}
                            </div>
                            <div className='p-col-12 p-md-3'>
                                <b><i className="pi pi-credit-card" style={{ fontSize: '1rem' }}></i> SIM Number :</b> {defaultMachine?.network_info?.Mobile_Number || 'NA'}
                            </div>
                            <div className='p-col-12 p-md-3'>
                                <b><i className="pi pi-tags" style={{ fontSize: '1rem' }}></i> Physical Number :</b> {defaultMachine?.network_info?.Physical_Number || 'NA'}
                            </div>
                            <div className='p-col-12 p-md-3'>
                                <b><i className="pi pi-clock" style={{ fontSize: '1rem' }}></i> Updated Time :</b> {`${new Date(defaultMachine?.network_info?.updated_at).toLocaleString()}` || 'NA'}
                            </div>
                        </div>
                    </AccordionTab>}
                    {(defaultMachine?.distributor_data && defaultMachine?.distributor_data?.userid) && <AccordionTab header="View Ownership">
                        <div className='p-grid'>
                            <div className='p-col-12 p-md-3'>
                                <b><i className="pi pi-wifi" style={{ fontSize: '1rem' }}></i> Partner Name :</b> {defaultMachine?.distributor_data?.name || 'NA'}
                            </div>
                            <div className='p-col-12 p-md-3'>
                                <b><i className="pi pi-tags" style={{ fontSize: '1rem' }}></i> Partner Type:</b> {defaultMachine?.distributor_data?.role || 'STRATEGIC'}
                            </div>
                        </div>
                    </AccordionTab>}
                    {defaultMachine?.mchmode?.paymode?.free == false && < AccordionTab header="Dispensing Option (Paid)">
                        <div className='p-grid' style={{ alignItems: 'center' }}>

                            {defaultMachine?.mchmode?.paymode?.rfid && <div className='p-col-12 p-md-3'>
                                <b><i className="pi pi-id-card" style={{ fontSize: '1rem' }}></i> RFID </b>{': YES'}
                            </div>}
                            {defaultMachine?.mchmode?.paymode?.rfid && <div className='p-col-12 p-md-3'>
                                <Button onClick={viewEmployee} icon="pi pi-user" label='View Employees' className="p-button-info p-m-1" />
                            </div>}

                            {defaultMachine?.mchmode?.paymode?.nfc && <div className='p-col-12 p-md-3'>
                                <b><i className="pi pi-credit-card" style={{ fontSize: '1rem' }}></i> NFC</b> {': YES'}
                            </div>}
                            {defaultMachine?.mchmode?.paymode?.online && <div className='p-col-12 p-md-3'>
                                <b><i className="pi pi-wallet" style={{ fontSize: '1rem' }}></i> UPI</b> {': YES'}
                            </div>}
                            {defaultMachine?.mchmode?.paymode?.online && <div className='p-col-12 p-md-3'>
                                <b><i className="pi pi-tags" style={{ fontSize: '1rem' }}></i> UPI Amount: </b> {`${defaultMachine?.subscription?.package?.ctvmfullcup} Rs`}
                            </div>}
                            {defaultMachine?.mchmode?.paymode?.password && <div className='p-col-12 p-md-3'>
                                <b><i className="pi pi-key" style={{ fontSize: '1rem' }}></i> Password</b> {': YES'}
                            </div>}
                        </div>
                    </AccordionTab>}
                    {rolePermissions?.overviewVersionTab && < AccordionTab header="Version Information">
                        <div className='p-grid' style={{ alignItems: 'center' }}>

                            {<div className='p-col-12 p-md-3'>
                                <b><i className="pi pi-tablet" style={{ fontSize: '1rem' }}></i> Operate Mode: </b>{defaultMachine?.operateMode}
                            </div>}
                            {<div className='p-col-12 p-md-3'>
                                <b><i className="pi pi-android" style={{ fontSize: '1rem' }}></i> {defaultMachine?.operateMode == 'DISPLAY' ? 'App Version' : 'ESP Version'} : </b>{defaultMachine?.version?.app || 'NA'}
                            </div>}
                            {<div className='p-col-12 p-md-3'>
                                <b><i className="pi pi-palette" style={{ fontSize: '1rem' }}></i> Gateway Version: </b>{defaultMachine?.version?.gateway || 'NA'}
                            </div>}
                            {<div className='p-col-12 p-md-3'>
                                <b><i className="pi pi-sliders-v" style={{ fontSize: '1rem' }}></i> CP Version: </b>{defaultMachine?.version?.cp || 'NA'}
                            </div>}

                        </div>
                    </AccordionTab>}
                </Accordion>
            </div >}

            <Dialog visible={barcodeDetailsDialog} style={{ width: '650px' }} header={`Barcode Details`} modal footer={barcodeDetailsFooter} onHide={() => { setBarcodeDetailsDialog(false) }} className='p-fluid' maximizable={true} blockScroll={true}>
                <div className='p-grid'>
                    <div className="card">
                        <div className="p-grid crud-demo">
                            <DataTable ref={dt} value={barcodeDetails} className="datatable-responsive" rows={10} dataKey="_id" rowHover emptyMessage="No barcode found." header={header1} exportFilename={`Barcode Details`} loading={facilityLoading}>
                                {/* <Column field="Sr.No." header="Sr.No." body={srBodyTemplate} style={{ width: '6rem' }} ></Column> */}
                                <Column field="barcode" header="Barcode" sortable body={CommonBodyForTable}></Column>
                                <Column field="stage" header="Stage" sortable body={CommonBodyForTable}></Column>
                            </DataTable>
                        </div>
                    </div>

                </div>
            </Dialog>
        </>
    )
}



