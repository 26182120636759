
export const exportReport = (reference) =>{ reference.current.exportCSV()}

export const barDataForBarChart = (dataLabels,dataValues,datasetLabel, colorData = '#00544d' ) =>{
    return {
        labels: dataLabels,
        datasets: [
            {
                label: datasetLabel,
                // backgroundColor: '#00544d',
                backgroundColor: colorData,
                borderColor: 'rgb(255, 99, 132)',
                data: dataValues,
                borderRadius: 5
            }
        ]
    };
}

export const DataForpieChart = (dataLabels,dataValues,datasetLabel, colorData = '#00544d' ) =>{
    return {
        labels: dataLabels,
        datasets: [
            {
                label: datasetLabel,
                // backgroundColor: '#00544d',
                backgroundColor: colorData,
                borderColor: 'rgb(255, 254, 255)',
                data: dataValues,
                borderRadius: 0
            }
        ]
    };
}

export const barOptionsForBarChart = (xLabel,yLabel) =>{

    return {
        plugins: {
            legend: {
                // labels: {
                //     color: '#A0A7B5'
                // }
                display:false
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color:  'rgba(160, 167, 181, .3)',
                },
                title: {
                    display: true,
                    text: xLabel
                }
            },
            
            y: {
                ticks: {
                    color: '#495057',
                    precision: 0
                },
                grid: {
                    color:  'rgba(160, 167, 181, .3)',
                },
                title: {
                    display: true,
                    text: yLabel
                }
            }
        },
        onClick: function (e, clickedElement) {

            // console.log(clickedElement);
            // console.log(e)
            // debugger;
            // var activePointLabel = this.getElementsAtEvent(e)[0]._model.label;
            // console.log(activePointLabel);
            // alert('hello');
        }
    };
}

export const copyData = (data) =>{
    navigator.clipboard.writeText(data);
}

export const showInformation = (reference) => {
    reference.current.show({ severity: 'info', summary: 'Copied', detail:'Number Copied to Clipboad', life: 1000 });
}

export const showInformation2 = (reference) => {
    reference.current.show({ severity: 'danger', summary: 'Copied', detail:'Payment Link to Clipboad', life: 1000 });
}

export const successToast = (reference, msg, summary = 'Successful') =>{ 
    reference.current.show({ severity: 'success', summary:`${summary}`, detail:`${msg}`, life: 3000 })
} 

export const failureToast = (reference, msg , summary = 'Failed') => { 
    reference.current.show({ severity: 'error', summary:`${summary}`, detail:`${msg}`, life: 3000 }) 
}

export const infoToast = (reference, msg , summary ) => { 
    reference.current.show({ severity: 'info', summary:`${summary}`, detail:`${msg}`, life: 3000 }) 
}


// ==================================

export const getFormattedString = (str) =>{

    function capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    const caps = str?.split(' ').map(capitalize).join(' ');
    return caps;
}

export const getFormattedAmount = (num) =>{
    const number = Number(num)

    const options = {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    };
    const formattedNumber = number.toLocaleString('en-IN', options);
    return formattedNumber;
}

export const getFormattedStatForAnalytics = (num) =>{
    const number = Number(num)
    const options = { style: 'decimal'};
    const formattedNumber = number.toLocaleString('en-IN', options);
    return formattedNumber;
}

export const convertDateFn = (date) =>{
    // const newDate = new Date(date);
    // const convertedDate1 = newDate.toString('YYYY-MM-dd')
    // const convertedDate = convertedDate1?.toString()?.replace('GMT+0530 (India Standard Time)','')
    const convertedDate = new Date(date).toLocaleString('en-IN');
    return convertedDate;
}

export function findDuplicates(arr, key) {
    if(arr){
        const thisArr =  arr.filter((obj, index) => {
            return arr.findIndex((item, i) => item[key] === obj[key] && i !== index) !== -1;
        }).map((x)=>{ return x.id})
        
        return [...new Set(thisArr)].toString()
    }
}

export function getStartAndEndDateOfMonth(month, year) {
    const startDate = new Date(year, month, 1).toISOString();
    const endDate = new Date(year, month + 1, 1).toISOString(); 
    return { startDate, endDate };
}