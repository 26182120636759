import React,{useState, useEffect, useRef, memo} from "react";
import { Chart } from "primereact/chart";
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CommonBodyForTable } from "../Common/CommonComponents";
// Service
import ApiServices from "../../service/api/ApiServices";
import { barDataForBarChart, barOptionsForBarChart } from "../Common/CommonFunctions";
const apiServices = new ApiServices;

const IndustryWiseAllCupsChart = (props) =>{

    const [allData,setAllData] = useState(null)
    const [dataLabels,setDataLabels] = useState([])
    const [dataValues,setDataValues] = useState([])
    const [loading, setLoading] = useState(true)

    const dt = useRef(null)

    useEffect(()=>{
        getData(props.paramForDailyCups)
    },[props.paramForDailyCups])


    const getData = async(data) =>{

        if(data){
            setLoading(true)
            const response = await apiServices.allMachineConsumption(data);
            if(response?.data?.data){
                // const finalArr = response?.data?.data.shift()
                response.data.data = response?.data?.data.map((obj) => {
                    const [date, month, year] = obj._id.split('-');  
                    obj._id = `${date}/${month}/${year}`;  
                    return obj;
                })
                console.log(response?.data?.data)
                setAllData(response?.data?.data)
                const labels = response?.data?.data?.map(a => a._id);
                setDataLabels(labels)
                const values = response?.data?.data?.map(a => a.cups);
                setDataValues(values)
                setLoading(false)
            }
        }
    }

    const exportCSV = () => { dt.current.exportCSV()}

    const barData = barDataForBarChart(dataLabels,dataValues,'Total Cups')
    const barOptions = barOptionsForBarChart('Date','Total Cup Count')

    return (
        <>
            <div className="p-col-12">
            <div className="card widget-visitor-graph">
                {/* <div className="card-header">
                    <span>Day-Wise Cup Consumption</span>
                </div> */}

                <div className="card-header">
                    <span></span>
                    <div className='p-col-sm-4'>
                        <Button icon="pi pi-download" title='Download Report' className="p-button-rounded p-button-primary p-mr-2 p-mb-2" onClick={exportCSV} />
                    </div>
                </div>

                {
                    loading ? 
                    <div className='spinner-div'>
                        <center><ProgressSpinner strokeWidth={7} animationDuration='4s'/></center>
                    </div>
                    :   
                    <div className="graph">
                        <Chart type="pie" data={barData} options={barOptions} />
                    </div>
                }
                
            </div>
            </div>  

            <DataTable  style={{display:'none'}} ref={dt} value={allData} dataKey="id" exportFilename="Day-Wise Cup Consumption"  className="datatable-responsive" emptyMessage="No data found.">
                <Column field="_id" header="Beverage Name"  body={CommonBodyForTable}></Column>
                <Column field="cups" header="Total Cup Count" body={CommonBodyForTable}></Column>
            </DataTable>

        </>
    )
}

export default memo(IndustryWiseAllCupsChart);