import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Carousel } from 'primereact/carousel';
import { RadioButton } from "primereact/radiobutton";
import { Toast } from 'primereact/toast';
// Components
import { AddNewCustomerNew } from "./AddNewCusomerNew";
import { ProductsForMachineOrder } from "./ProductsForMachineOrder";
import { KettleUserForm } from "./KettleUserForm";
import { payloadData, categories, machineData } from "./Constants";
// Common
import { successToast, failureToast } from "../Common/CommonFunctions";
// Services
import ApiServices from "../../service/api/ApiServices";
import { KettleProducts } from "./KettleProducts";
const apiServices = new ApiServices()
// LocalStorage
const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

// =======================================================================================
// =======================================================================================

export const MachineOrderDash = () => {

    const [selectedCategories, setSelectedCategories] = useState([categories[1]]);
    const [facilityData, setFacilityData] = useState([{ name: 'None' }])
    // const [facilityData, setFacilityData] = useState([])
    // const [selectedFacility, setSelectedFacility] = useState({name : 'None'})
    const [selectedFacility, setSelectedFacility] = useState(null)
    const [selectedFac, setSelectedFac] = useState(null)
    const [productPayload, setProductPayload] = useState({});
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [openKettleFormDialog, setOpenKettleFormDialog] = useState(false);
    const [tapriPreferenceDialog, setTapriPreferenceDialog] = useState(false);
    const [isProceeded, setIsProceeded] = useState(false);
    const [proceedPayload, setProceedPayload] = useState(payloadData);
    const [allRanges, setAllRanges] = useState([]);
    const [allPrices, setAllPrices] = useState([]);
    const [selectedRange, setSelectedRange] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [rangeData, setRangeData] = useState(null);
    const [enteredCno, setEnteredCno] = useState('');
    const [enteredOtp, setEnteredOtp] = useState('');
    const [customerFormDialog, setCustomerFormDialog] = useState(false);
    const [enteredBtn, setEnteredBtn] = useState(false);
    const [createUserDialog, setCreateUserDialog] = useState(false);
    const [latLong, setLatLong] = useState(null);
    const [verifiedUserData, setVerifiedUserData] = useState(null);
    const [registeredUser, setRegisteredUser] = useState(null);
    const [newUserData, setNewUserData] = useState(null);
    const [resendFlag, setResendFlag] = useState(true);
    const [selectfacilityDialog, setSelectfacilityDialog] = useState(false);
    const [clickable, setClickable] = useState(true)
    const toast = useRef(null);

    const responsiveOptions = [
        {
            breakpoint: '1199px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '991px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    const tapriBrands = [
        {
            name: 'Tapri',
            image: 'cherise_tapri_logo.png',
            description: 'Premium beverages',
            price: 'Rs 7/cup',
            selected: true
        },
        {
            name: 'TKaffe',
            image: 'tkaffee_logo.png',
            description: 'Affordable beverages',
            price: 'Rs 4/cup',
            selected: false
        }
    ]

    const paymentTermsOptions = [
        { name: 'Prepaid' },
        { name: 'Postpaid' },
        { name: 'Partial' }
    ]

    useEffect(() => {
        // setCreateUserDialog(true)
        // setTapriPreferenceDialog(true)
        // setOpenKettleFormDialog(true);
        getCurrentLoc()
        setDefaultVal()
    }, [])

    const setDefaultVal = () => { setNewUserData(null) }

    const getCurrentLoc = () => {
        navigator.geolocation.getCurrentPosition(position => {
            console.log(position)
            setLatLong({ currentLatitude: position.coords.latitude, currentLongitude: position.coords.longitude })
        });
    }

    const openDialog = (data) => {
        getDefault()
        console.log(data);
        // setSelectedBrand(data)
        setSelectedBrand(data?.brand)
        setSelectedCategory(data)

        if (data.brand == "MERI_TAPRI") {
            if (initialValue?.userType == "customer") {
                setCustomerFormDialog(true)
            } else {
                setCustomerFormDialog(true)
                // setOpenKettleFormDialog(true)
            }
        }

        if (data.brand == "TAPRI") {
            (initialValue?.userType == "customer") ? setCustomerFormDialog(true) : setCustomerFormDialog(true);
        }
    }

    const getUserData = async () => {
        const userResponse = await apiServices.getuserById({ userid: initialValue.userid })
        const combined = userResponse?.data?.data?.length > 0 ? { ...initialValue, dashData: userResponse.data.data[0] } : initialValue;
        setVerifiedUserData(combined)
        getTapriData(false, combined)
    }

    const getDefault = () => {
        setSelectedCategories([categories[1]]);
        setFacilityData([])
        setSelectedFacility(null)
        setSelectedFac(null)
        setProductPayload({});
        setSelectedBrand(null)
        setOpenKettleFormDialog(false);
        setTapriPreferenceDialog(false);
        setIsProceeded(false);
        setProceedPayload(payloadData);
        setAllRanges([]);
        setAllPrices([]);
        setSelectedRange([]);
        setSelectedPlan(null);
        setRangeData(null);
        setEnteredCno('');
        setEnteredOtp('');
        setCustomerFormDialog(false);
        setEnteredBtn(false);
        setCreateUserDialog(false);
        // setLatLong(null);
        setVerifiedUserData(null);
        setRegisteredUser(null);
        setNewUserData(null);
        setResendFlag(true);
        setSelectfacilityDialog(false);
    }

    const openPreference = () => {
        setTapriPreferenceDialog(true)
        getTapriData()
    }

    const getTapriData = async (data, userData) => {

        const response = data ? await apiServices.getRanges(data) : await apiServices.getRanges();
        setRangeData(response?.data?.response_obj)
        setAllRanges(response?.data?.response_obj?.ranges)

        if (userData?.dashData?.cup_cost) {

            const data = response?.data?.response_obj?.prices.map((x) => {
                return {
                    ...x,
                    price: userData?.dashData?.billing_pattern === "Cupwise" ? userData?.dashData?.cup_cost : userData?.dashData?.packet_cost,
                    type: userData?.dashData?.billing_pattern
                }
            })
            setAllPrices(data)
            setSelectedBrand(data[0])

        } else {
            setAllPrices(response?.data?.response_obj?.prices)
            setSelectedBrand(response?.data?.response_obj?.prices[0])
        }

        setSelectedRange(response?.data?.response_obj?.ranges[0])
        getPlans(response?.data?.response_obj.prices[0].brand, response?.data?.response_obj.ranges[0].id)
    }

    const openPreferenceFromCust = (data) => {
        // console.log(data);
        setTapriPreferenceDialog(true)
        setRegisteredUser(data)
        getTapriAfterSignUp(data)
    }

    const getTapriAfterSignUp = async (dataFromCust) => {
        const response = await apiServices.getRanges();
        setRangeData(response?.data?.response_obj)
        setAllRanges(response?.data?.response_obj?.ranges)
        const data = response?.data?.response_obj?.prices.map((x) => {
            return {
                ...x,
                price: dataFromCust?.billing_type == "Cupwise" ? dataFromCust?.cup_cost : dataFromCust?.packet_cost,
                type: dataFromCust?.billing_type
            }
        })
        const fildata = data?.filter((x) => { return x.title == 'Tapri' })
        setAllPrices(fildata)
        setSelectedBrand(fildata[0])
        setSelectedRange(response?.data?.response_obj?.ranges[0])
        getPlans(response?.data?.response_obj.prices[0].brand, response?.data?.response_obj.ranges[0].id)
    }

    const getSignedUpData = (data) => { setNewUserData(data) }

    const changeRange = (e) => {
        setSelectedRange(e.value)
        getPlans(selectedBrand.brand, e.value.id)
    }

    const getPlans = async (data, data1) => {
        const params = { "brand_id": data, "range_id": data1 };
        const response = await apiServices.getOwnerShipPlans(params);
        setSelectedPlan(response?.data?.response_obj[0])
    }

    const proceedFromPreference = () => {

        // const payload = { 
        //     brand: selectedBrand?.brand, 
        //     lane: selectedRange?.id == '632c2cd73a26f043142bf807' ? 3 : 4, 
        //     plan_type: 'FREE', 
        //     price: selectedBrand?.price, 
        //     type : selectedBrand?.type 
        // }

        console.log('Register User', registeredUser);

        const payload = {
            brand: selectedBrand?.brand,
            lane: selectedRange?.id == '632c2cd73a26f043142bf807' ? 3 : 4,
            plan_type: 'FREE',
            price: selectedBrand?.price,
            billing_pattern: registeredUser?.billing_type,
            packet_cost: registeredUser?.packet_cost,
            speciality_rate: registeredUser?.speciality_rate,
            offset: Number(registeredUser?.cup_quantity) - 60
        }

        console.log(payload);
        setProductPayload(payload)
        setIsProceeded(true)
        setTapriPreferenceDialog(false)
    }

    const selectTapriBrand = (data) => {
        data?.title == "Tkaffe" && failureToast(toast, 'You cannot select this for now.', 'Coming Soon');
    }

    const resendOtp = () => {
        if (!resendFlag) {
            setResendFlag(true)
            setTimeout(function () { setResendFlag(false) }, 30000);
            sendOtp()
            successToast(toast, 'Otp Resend successfully')
        }
    }

    const hideCustDialog = () => {
        setCustomerFormDialog(false);
        setEnteredBtn(false);
    }

    const header = (data) => { return (<center> <img className="p-m-4" alt="Card" src={`assets/demo/images/Icon/${data.img}`} style={{ width: '45%' }} /> </center>) }
    const footer = (data) => { return (<div className="flex flex-wrap justify-content-end gap-2"> <center> <h5>{data.name}</h5> </center> </div>) }

    const changeCust = (e) => {
        const isValid = isNaN(Number(e.target.value));
        if (e.target.name === "cno") {
            !isValid && setEnteredCno(e.target.value)
        }
        if (e.target.name === "otp") {
            !isValid && setEnteredOtp(e.target.value)
        }
    }

    const handleKeyDown = (e) => {
        if (enteredCno?.length === 10 && e.key === 'Enter') {
            if (initialValue?.userType === "customer") {
                if (initialValue?.cno === enteredCno || initialValue?.cno === ('+91' + enteredCno)) {
                    enterHere()
                } else {
                    failureToast(toast, 'Please enter registered mobile number')
                }
            } else {
                enterHere()
            }
        }
    }

    const enterHere = () => {
        sendOtp()
        setTimeout(() => {
            setResendFlag(false)
        }, 10000);
    }

    const handleKeyDownForOtp = (e) => { if (enteredOtp?.length == 4 && e.key === 'Enter') { verifyOtp(); } }

    const verifyOtp = async () => {

        if (clickable) {
            setClickable(false)
            setTimeout(() => { setClickable(true) }, 3000);

            const param = { "country_code": "+91", "contact": enteredCno, "otp": enteredOtp }
            const response = await apiServices.verifyOtpToCustomer(param)

            console.log(response?.data);

            if (response?.data?.response_code == 1) {
                setDefaultCustForm()

                if (response?.data?.response_obj?.cves_commercial_user) {

                    const userResponse = await apiServices.getuserById({ userid: response?.data?.response_obj?.ctvm_user_data?.userid })
                    // console.log(userResponse.data.data);     
                    const combined = userResponse?.data?.data?.length > 0 ? { ...response?.data?.response_obj, dashData: userResponse.data.data[0] } : response?.data?.response_obj;
                    // console.log(combined);
                    // setVerifiedUserData(combined)
                    // setRegisteredUser(combined)

                    if (selectedBrand === 'TAPRI') {
                        if ((userResponse?.data?.data?.length > 0 && userResponse?.data?.data[0]?.userType == "customer") && response?.data?.response_obj?.facilities?.length > 0) {
                            setFacilityData([{ name: 'None' }, ...response?.data?.response_obj?.facilities])
                            // setFacilityData([...facilityData,...response?.data?.response_obj?.facilities])
                            setSelectfacilityDialog(true)
                            const appOptions = { headers: { "Authorization": combined?.token, "Content-type": "application/json" } };
                            getTapriData(appOptions, combined)
                            setVerifiedUserData(combined)
                            setRegisteredUser(combined)
                        } else if (userResponse?.data?.data?.length > 0 && userResponse?.data?.data[0]?.userType != "customer") {
                            failureToast(toast, 'This number is already register with the system with different user type.')
                            setVerifiedUserData(null)
                            setRegisteredUser(null)
                        } else if (response?.data?.response_obj?.facilities?.length == 0) {
                            setCreateUserDialog(true)
                            setVerifiedUserData(combined)
                            setRegisteredUser(combined)
                        }
                    }

                    if (selectedBrand === 'MERI_TAPRI') {
                        setOpenKettleFormDialog(true);
                        setVerifiedUserData(combined)
                        setRegisteredUser(combined)
                    }

                } else {
                    failureToast(toast, 'Your demo site is still activated, please delete it before proceeding for new CVES order.')
                }

            } else {
                failureToast(toast, response?.data?.response_message)
            }
        }
    }

    const sendOtp = async () => {
        if (enteredCno.length == 10) {
            setEnteredBtn(true)
            const response = await apiServices.sendOtpToCustomer({ "country_code": "+91", "contact": enteredCno })
            response?.data?.response_code == 1 && setEnteredBtn(true)
        }
    }

    const setDefaultCustForm = () => {
        setCustomerFormDialog(false)
        setEnteredCno('')
        setEnteredOtp('')
        setEnteredBtn(false)
    }

    const productTemplate = (product) => {
        return (
            <div className={`border-1 surface-border border-round m-2 text-center py-5 px-3`} onClick={() => { selectTapriBrand(product) }} style={{ border: product?.title == "Tapri" ? '3px solid #00544d' : '1px solid #44486D', borderRadius: '50px', padding: '10px', margin: '10px', cursor: 'pointer' }} >
                <div className="mb-3">
                    <center>
                        <img src={`${product.image}`} alt={product.title} className="w-6 shadow-2" style={{ height: '150px' }} />
                    </center>
                </div>
                <center>
                    <div>
                        <h4>{product.title}</h4>
                        <small>{product.description}</small>
                        <p><b> ₹ {product.price}/{selectedBrand?.type == 'PacketWise' ? 'Packet' : 'Cup'}</b></p>
                    </div>
                </center>
            </div>
        );
    };

    const changeFac = (e) => { setSelectedFacility(e.target.value) }

    const hideFacDialog = () => {
        setSelectfacilityDialog(false)
        setFacilityData([{ name: 'None' }])
        setSelectedFacility(null)
    }

    const submitFacility = (e) => {
        e.preventDefault()
        hideFacDialog()
        setCreateUserDialog(true)
        if (selectedFacility?.name == 'None') {
            setSelectedFac(null)
        } else {
            setSelectedFac(selectedFacility)
        }
    }

    const onSubmitKettleReg = () => { setIsProceeded(true) }

    const tapriPreferenceDialogFooter = (
        <>
            {/* <Button label="Cancel" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={()=>{setTapriPreferenceDialog(false)}}/> */}
            {rangeData && <Button label="Proceed" icon="pi pi-check" className="p-button-success p-mr-2 p-mb-2" onClick={proceedFromPreference} />}
            {/* <Button label="Proceed" icon="pi pi-check" type='submit' form="pref-form" className="p-button-success p-mr-2 p-mb-2" /> */}
        </>
    );

    const customerFormDialogFooter = (
        <>
            {enteredBtn && <Button label="Resend OTP" icon="pi pi-send" className="p-button-success p-mr-2 p-mb-2" onClick={resendOtp} disabled={resendFlag} />}
            <Button label="Cancel" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={hideCustDialog} />
            {(enteredOtp?.length == 4) && <Button label="Submit" icon="pi pi-check" className="p-button-success p-mr-2 p-mb-2" onClick={() => { verifyOtp() }} />}
            {
                (!enteredBtn && enteredCno.length == 10) && <Button label="Send OTP" icon="pi pi-check" className="p-button-success p-mr-2 p-mb-2" onClick={sendOtp} />
            }
        </>
    );

    const facilityDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={() => { hideFacDialog() }} />
            <Button label="Submit" icon="pi pi-check" type='submit' form="fac-form" className="p-button-success p-mr-2 p-mb-2" />
        </>
    );



    return (

        <>
            <Toast ref={toast} position="center" />

            {
                (!isProceeded) &&
                <div className="card">
                    <h5 className="p-m-3">Select Category</h5>
                    <div className="card">
                        <div className="p-grid">
                            {
                                machineData.map((x, i) => {
                                    return (
                                        <div className="p-col-12 p-md-3" key={i} style={{ cursor: 'pointer' }}>
                                            <div onClick={() => { openDialog(x) }}>
                                                <Card header={header(x)} footer={footer(x)} className="md:w-25rem" style={{ background: '#00544d12', borderRadius: '15px' }}></Card>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            }

            {
                (isProceeded && selectedCategory?.brand == "TAPRI") &&
                // (isProceeded ) && 
                <ProductsForMachineOrder
                    latLong={latLong}
                    verifiedUserData={verifiedUserData}
                    productPayload={productPayload}
                    selectedPlan={selectedPlan}
                    selectedBrand={selectedBrand}
                    registeredUser={registeredUser}
                    setIsProceeded={setIsProceeded}
                    newUserData={newUserData}
                    selectedFac={selectedFac}
                />
            }

            <KettleUserForm
                openKettleFormDialog={openKettleFormDialog}
                latLong={latLong}
                verifiedUserData={verifiedUserData}
                onSubmitKettleReg={onSubmitKettleReg}
                setIsProceeded={setIsProceeded}
            />

            {/* {
                (isProceeded && selectedCategory?.brand == "MERI_TAPRI") &&
                <KettleProducts />
            } */}

            <AddNewCustomerNew
                createUserDialog={createUserDialog}
                selectedFac={selectedFac}
                verifiedUserData={verifiedUserData}
                latLong={latLong}
                openPreference={openPreference}
                openPreferenceFromCust={openPreferenceFromCust}
                getSignedUpData={getSignedUpData}
                setCreateUserDialog={setCreateUserDialog}
            />

            <Dialog visible={tapriPreferenceDialog} style={{ width: '850px' }} header={"Tapri Order"} modal footer={tapriPreferenceDialogFooter} className='p-fluid' blockScroll={true}>
                {
                    !rangeData ?
                        <center><i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i></center>
                        :
                        <div className="p-field">
                            <h5>Select average daily cup consumption</h5>

                            {
                                allRanges.map((x) => {
                                    return (
                                        <div key={x.id} className="flex align-items-center p-m-4">
                                            <RadioButton inputId={x.id} name="range" value={x} onChange={changeRange} checked={selectedRange.id == x.id} />
                                            <label htmlFor={x.id} className="ml-2"> <b>{x.title}</b> <small>( {x.description} )</small></label>
                                        </div>
                                    )
                                })
                            }

                            <h5>CHOOSE BRAND</h5>
                            <Carousel value={allPrices} numVisible={1} numScroll={1} responsiveOptions={responsiveOptions} itemTemplate={productTemplate} showIndicators={false} showNavigators={false} />

                            <h5>RECOMMENDED MACHINE FOR YOU</h5>
                            <div className="card" style={{ backgroundColor: '#00544d1f' }}>
                                <div className="p-grid">
                                    <div className="p-col-10">
                                        <h6>{selectedPlan?.title}</h6>
                                        {selectedPlan?.highlights.map((x, i) => (<p key={i}> <i className="pi pi-check-circle"></i>  {x.title}</p>))}
                                    </div>
                                    <div className="p-col-2">
                                        <img src={`${selectedPlan?.image}`} alt='machine' className="w-6 shadow-2" style={{ height: '150px' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </Dialog>

            <Dialog visible={customerFormDialog} style={{ width: '550px' }} header={`Place ${selectedCategory?.name} Order For Customer`} modal footer={customerFormDialogFooter} onHide={setDefaultCustForm} className='p-fluid' blockScroll={true}>

                <div className="p-field">
                    <label htmlFor="phoneNum">Phone Number </label>
                    <InputText type="text" id="phoneNum" name="cno" placeholder="Phone Number" value={enteredCno} maxLength={10} onChange={changeCust} onKeyDown={(e) => handleKeyDown(e)} disabled={enteredBtn} autoFocus />
                    <small className="p-error">*Enter Customer's 10 digit phone number</small>
                </div>

                {
                    enteredBtn &&
                    <div className="p-field">
                        <label htmlFor="enterOTP">Enter OTP</label>
                        <InputText id="enterOTP" name="otp" placeholder="Enter OTP" value={enteredOtp} maxLength={4} onChange={changeCust} onKeyDown={(e) => handleKeyDownForOtp(e)} autoFocus />
                    </div>
                }

            </Dialog>

            <Dialog visible={selectfacilityDialog} style={{ width: '550px' }} header="Select Facility" modal footer={facilityDialogFooter} onHide={() => { hideFacDialog() }} className='p-fluid' blockScroll={true}>
                <form onSubmit={submitFacility} id="fac-form">
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="p-field">
                                <label htmlFor="facility">Select Facility</label>
                                <Dropdown id="facility" name="facility" value={selectedFacility} onChange={changeFac} options={facilityData} optionLabel="name" placeholder="Select Facility" className="multiselect-custom" filter required autoFocus />
                            </div>
                        </div>

                        {
                            (selectedFacility?.name !== 'None' && selectedFacility !== null) &&
                            <>
                                <hr></hr>
                                <div className="p-m-2">
                                    <h5>Billing Address : </h5>
                                    <p>{selectedFacility?.facility_billing_details?.addr}</p>
                                    <p>{selectedFacility?.facility_billing_details?.country?.name}, {selectedFacility?.facility_billing_details?.state?.name}, {selectedFacility?.facility_billing_details?.city?.name}, {selectedFacility?.facility_billing_details?.pincode}</p>
                                    <hr></hr>
                                    <h5>SHIPPING ADDRESS : </h5>
                                    <p>{selectedFacility?.shipping_address?.address}</p>
                                    <p>{selectedFacility?.shipping_address?.country?.name}, {selectedFacility?.shipping_address?.state?.name}, {selectedFacility?.shipping_address?.city?.name}, {selectedFacility?.shipping_address?.pincode}</p>
                                </div>
                            </>
                        }

                    </div>
                </form>
            </Dialog>

        </>
    )
}

