import React, { useEffect, useState } from "react";
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
// Components
import FlavorWiseChart from "./FlavorWiseChart";
import DayWiseAllCupsChart from "./DayWiseAllCupsChart";
import BeverageWiseDailyChart from "./BeverageWiseDailyChart";
import DetailedCupConsumption from "./DetailedCupConsumption";
// Services
import { managementCheck } from "../../service/admin/superadmin";
import ApiServices from "../../service/api/ApiServices";
import CityWiseAllCupsChart from "./CityWiseAllCupsChart";
import StateWiseAllCupsChart from "./StateWiseAllCupsChart";
import IndustryWiseAllCupsChart from "./IndustryWiseAllCupsChart";
const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);
const apiServices = new ApiServices()

export const AnalyticalCharts = (props) => {

    const [loading, setLoading] = useState(true)
    const [flavourChartData, setFlavourChartData] = useState(null)
    const [flavourData, setFlavourData] = useState(null)
    const [flavourNameData, setFlavourNameData] = useState(null)
    const [flavourColorData, setFlavourColorData] = useState(null)
    const [paramForDailyCups, setParamForDailyCups] = useState(null)
    const [singleCustomer, setSingleCustomer] = useState(false)
    const [paramForBevWiseDaily, setParamForBevWiseDaily] = useState(null)
    const [cupConsumData, setCupConsumData] = useState(null)
    const [timeCupData, setTimeCupData] = useState(null)
    const [allCupConsumeData, setAllCupConsumeData] = useState(null)
    const [paramsData, setParamsData] = useState(null)
    const [settime, setTimeValue] = useState('')
    const [machineSelectValue, setMachineselectValue] = useState('')

    const [tapriFlavourChartData, setTapriFlavourChartData] = useState(null)




    useEffect(() => {
        console.log("=======================Analytical Chart===================");
        getFlavourData(props.flavourWiseData)
    }, [props.flavourWiseData])

    useEffect(() => {
        setParamForDailyCups(props.paramForDailyCups)
    }, [props.paramForDailyCups])

    useEffect(() => {
        setSingleCustomer(props.customerValue ? true : false)

        console.log(props.customerValue)
    }, [props.customerValue])

    useEffect(() => {
        setParamForBevWiseDaily(props.paramForBevWiseDaily)
    }, [props.paramForBevWiseDaily])

    useEffect(() => {
        setCupConsumData(props.cupCData)
    }, [props.cupCData])

    useEffect(() => {
        setTimeCupData(props.TimeCData)
    }, [props.TimeCData])

    useEffect(() => {
        setAllCupConsumeData(props.allCupConsumeData)
    }, [props.allCupConsumeData])

    useEffect(() => {
        setParamsData(props.userStatParam)
        setTimeValue(props.userStatParam?.time)
    }, [props.userStatParam])


    useEffect(() => {
        getTapriFlavourData(props.bevChartData)
    }, [props.bevChartData])

    useEffect(() => {
        setMachineselectValue(props.machineselectValue.customer)
    }, [props.machineselectValue])


    const getFlavourData = async (arr) => {

        arr.sort((a, b) => Number(b.totalCup) - Number(a.totalCup));

        let result = arr.map(a => a.totalCup);
        let resultedNames = arr.map(a => a._id);
        let resultedColors = arr.map(a => a.color);

        setFlavourChartData(arr)

        setFlavourData(result)
        setFlavourNameData(resultedNames)
        setFlavourColorData(resultedColors)

    }

    const getTapriFlavourData = async (data) => {
        if (data) {

            const response1 = await apiServices.getMaterialType();
            const arr = [];
            response1?.data?.mate.forEach((element) => {
                const materialName = element.materialName;
                data.map((x) => {
                    if (materialName == x._id) {
                        arr.push({ ...x, color: element.color })
                    }
                });
            });

            arr.sort((a, b) => Number(b.totalCup) - Number(a.totalCup));

            let result = arr.map(a => a.totalCup);
            let resultedNames = arr.map(a => a._id);
            let resultedColors = arr.map(a => a.color);

            // console.log(arr);
            // console.log(resultedNames);
            // console.log(resultedColors);

            setTapriFlavourChartData(arr)
            // setFlavourData(result)
            // setFlavourNameData(resultedNames)
            // setFlavourColorData(resultedColors)
        }
    }


    return (

        <>
            <div className="p-mt-5">
                {
                    props.activeTabNumber == 0 ?
                        <TabView >
                            <TabPanel header="Flavorwise Cup Consumptions">
                                <FlavorWiseChart flavourChartData={flavourChartData} loading={props.masterLoader} />
                            </TabPanel>

                            {
                                (managementCheck() || ['fintech', 'invenstor'].includes(initialValue?.userType)) ?
                                    <TabPanel header="">
                                    </TabPanel> :
                                    <TabPanel header="Day Wise Cup Consumptions">
                                        <DayWiseAllCupsChart paramForDailyCups={paramForDailyCups} />
                                    </TabPanel>
                            } 

                            {
                                (settime == 'all' && machineSelectValue == 'All') ?
                                <TabPanel header="State Wise CVES Distribution">
                                <StateWiseAllCupsChart paramForDailyCups={paramsData} />
                                </TabPanel> : 
                                <TabPanel header="">
                                </TabPanel> 
                            } 

                            {
                                (settime == 'all' && machineSelectValue == 'All') ?
                                <TabPanel header="City Wise CVES Distribution">
                                    <CityWiseAllCupsChart paramForDailyCups={paramsData} />
                                </TabPanel> : 
                                <TabPanel header="">
                                </TabPanel> 
                            } 


                            {/* {
                                (managementCheck() || ['fintech', 'invenstor'].includes(initialValue?.userType)) ?
                                    <TabPanel header="">
                                    </TabPanel> :
                                    <TabPanel header="Industry Wise Cup Consumptions">
                                        <IndustryWiseAllCupsChart paramForDailyCups={paramForDailyCups} />
                                    </TabPanel>
                            } */}

                            {
                                (!['fintech', 'invenstor'].includes(initialValue?.userType) && singleCustomer || initialValue.userType == 'facility') ?
                                    <TabPanel header="Beverage Wise Daily Cup Consumptions">
                                        <BeverageWiseDailyChart paramForBevWiseDaily={paramForBevWiseDaily} />
                                    </TabPanel> :
                                    <TabPanel header="">
                                    </TabPanel>
                            }

                            {
                                (!['fintech', 'invenstor'].includes(initialValue?.userType) && singleCustomer || initialValue.userType == 'facility') ?
                                    <TabPanel header="Detailed Cup Consumptions">
                                        <DetailedCupConsumption cupConsumData={cupConsumData} timeCupData={timeCupData} allCupConsumeData={allCupConsumeData} allBeverages={props.allBeverages} getmachineStats={props.getmachineStats} paramsData={paramsData} />
                                    </TabPanel> :
                                    <TabPanel header="">
                                    </TabPanel>
                            }

                        </TabView>

                        :

                        <TabView >
                            <TabPanel header="Flavorwise Cup Consumptions">
                                <FlavorWiseChart tapriFlavourChartData={tapriFlavourChartData} />
                            </TabPanel>
                            {initialValue?.userType != 'invenstor' && <TabPanel header="Day Wise Cup Consumptions">
                                <DayWiseAllCupsChart paramForDailyCups={paramForDailyCups} />
                            </TabPanel>}
                        </TabView>

                }

            </div>
        </>
    )
}