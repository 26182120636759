import React,{useState, useEffect, useRef, memo} from "react";
import { Chart } from "primereact/chart";
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CommonBodyForTable } from "../Common/CommonComponents";
import { Dialog } from 'primereact/dialog';

// Service
import ApiServices from "../../service/api/ApiServices";
import { barDataForBarChart, barOptionsForBarChart, DataForpieChart } from "../Common/CommonFunctions";
import { commonCloseDialogFooter, srBodyTemplate, totalcostTemplate } from '../Common/CommonComponents';

const apiServices = new ApiServices;
const colorData = ["#b84c7d", "#50b47b", "#8650a6", "#86a542", "#6881d8", "#c18739"];
const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);
const StateWiseAllCupsChart = (props) =>{

    const [allData,setAllData] = useState(null)
    const [dataLabels,setDataLabels] = useState(null)
    const [dataValues,setDataValues] = useState(null)
    const [loading, setLoading] = useState(true)
    const [chartMasterData, setChartMasterData] = useState(null)
    const [detailedDialog, setDetailedDialog] = useState(false)
    const [detailedData, setDetailedData] = useState(null)
    const [stateName, setStateName] = useState(null)

    const dt = useRef(null)

    useEffect(()=>{
        getData(props.paramForDailyCups)
    },[props.paramForDailyCups])

    const exportCSV = () => { dt.current.exportCSV()}


    const header = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <h6 className='p-mt-2'>{stateName}</h6>
            </span >
            <span className="p-input-icon-right" >
                <div className="p-grid">
                    <div className='p-col-10'></div>
                    <div className='p-col-2'> <Button icon="pi pi-download" className='p-ml-100 custom-group-btn p-button-rounded' onClick={exportCSV} /></div>
                </div>
            </span>
        </div>
    );

    const getBarData = (data) => {
        if (data.length > 0) {
            setDetailedDialog(true)
            const chartIndex = chartMasterData[data[0].index];
            console.log(chartIndex);
            getChartReport(chartIndex)
            setDetailedData(chartIndex?.cves_data)
            setStateName(chartIndex?.state_name)
        }
    }

    const getData = async(data) =>{
        if(data){
            setLoading(true)
            let params = {}
            params = {...data.params,userId:initialValue.userid,userType:initialValue.userType, groupByState: true}
            const response = await apiServices.stateAndcityWiseConsumption(params);
            if(response?.data?.data){
                // const finalArr = response?.data?.data.shift()
                // response.data.data = response?.data?.data.map((obj) => {
                //     const [date, month, year] = obj._id.split('-');  
                //     obj._id = `${date}/${month}/${year}`;  
                //     return obj;
                // })
                setChartMasterData(response?.data?.data)
                console.log(response?.data?.data)
                setAllData(response?.data?.data)
                const labels = response?.data?.data?.map(a => a.state_name);
                setDataLabels(labels)
                const values = response?.data?.data?.map(a => a.cves_count);
                setDataValues(values)
                setLoading(false)
            }
        }
    }


    const barOptionsForBarChart2 = (xLabel, yLabel) => {

        return {
            plugins: {
                legend: {
                    labels: {
                        usePointStyle: true
                    }
                }
            },
            scales: {
                x: {
                    // ticks: {
                    //     color: '#495057'
                    // },
                    // grid: {
                    //     color: 'rgba(160, 167, 181, .3)',
                    // },
                    title: {
                        display: true,
                        // text: xLabel
                    }
                },

                y: {
                    // ticks: {
                    //     color: '#495057',
                    //     precision: 0
                    // },
                    // grid: {
                    //     color: 'rgba(160, 167, 181, .3)',
                    // },
                    title: {
                        // display: true,
                        text: yLabel
                    }
                }
            },
            onClick: function (e, clickedElement) {
                console.log(clickedElement)
                console.log(e)
                getBarData(clickedElement)
                // console.log(clickedElement);
                // console.log(e)
                // debugger;
                // var activePointLabel = this.getElementsAtEvent(e)[0]._model.label;
                // console.log(activePointLabel);
                // alert('hello');
            }
        };
    }

    const barOptionsForBarChart3 = (xLabel,yLabel) =>{

        return {
            plugins: {
                legend: {
                    // labels: {
                    //     color: '#A0A7B5'
                    // }
                    display:false
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color:  'rgba(160, 167, 181, .3)',
                    },
                    title: {
                        display: true,
                        text: xLabel
                    }
                },
                
                y: {
                    ticks: {
                        color: '#495057',
                        precision: 0
                    },
                    grid: {
                        color:  'rgba(160, 167, 181, .3)',
                    },
                    title: {
                        display: true,
                        text: yLabel
                    }
                }
            },
            onClick: function (e, clickedElement) {
                console.log(clickedElement);
                console.log(e)
                getBarData(clickedElement)
                // debugger;
                // var activePointLabel = this.getElementsAtEvent(e)[0]._model.label;
                // console.log(activePointLabel);
                // alert('hello');
            }
        };
    }

    const barData = barDataForBarChart(dataLabels,dataValues,'CVES Count', colorData)
    const barOptions = barOptionsForBarChart3('States', 'CVES Count')
    const pieChartData = DataForpieChart(dataLabels, dataValues, 'CVES Count', colorData)
    const pieOptions = barOptionsForBarChart2('States', 'CVES Count')

    const getChartReport = async (chartIndex) => {
        console.log(chartIndex)
    }

    const hideDetailed = () => { setDetailedDialog(false) }
    const sensorFooter = commonCloseDialogFooter('Close', hideDetailed)

    return (
        <>
            <div className="p-grid">
                <div className="p-col-12 p-md-12">
                    <div className="card">
                        {
                            loading ?
                                <>
                                    <div className="spinner-div">
                                        <center><ProgressSpinner strokeWidth="7" animationDuration='4s' /></center>
                                    </div>
                                </>
                                :
                                <>
                                    <h3>State Wise CVES-Distribution</h3>
                                    <Chart type="bar" data={barData} options={barOptions} />
                                </>
                        }

                    </div>
                </div>
                {/* <div className="p-col-12 p-md-4">
                    <div className="card">
                        {
                            loading ?
                                <>
                                    <div className="spinner-div">
                                        <center><ProgressSpinner strokeWidth="7" animationDuration='4s' /></center>
                                    </div>
                                </>
                                :
                                <>
                                    <h3>State Wise CVES-Distribution</h3>
                                    <Chart type="pie" data={pieChartData} options={pieOptions} />
                                </>
                        }

                    </div>
                </div> */}
            </div>  

            <Dialog className="p-fluid" header={`State Wise CVES Distribution`} visible={detailedDialog} style={{ width: '50vw' }} footer={sensorFooter} onHide={hideDetailed} maximizable={true} blockScroll={true}>
                <div className="p-grid crud-demo">
                    <div className="p-col-12">
                        <div className="card">
                            <DataTable ref={dt} value={detailedData}
                                dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                                emptyMessage="No data found." header={header} exportFilename={stateName} >
                                <Column field="srid" header="Sr.No." body={srBodyTemplate} style={{ width: '4rem' }} exportable={false}></Column>
                                <Column field="id" header="CVES ID" body={CommonBodyForTable} ></Column>
                                <Column field="siteName" header="Account Name" body={CommonBodyForTable} ></Column>
                                <Column field="facid" header="Facility ID" body={CommonBodyForTable} ></Column>
                                {/* <Column field="cups" header="Cups" body={CommonBodyForTable} ></Column> */}
                                {/* <Column field="Total Cost" header="Total Amount" body={totalcostTemplate} ></Column> */}
                                {/* <Column field="subid" header="Sub Id" body={CommonBodyForTable} ></Column> */}
                                <Column field="brand" header="Brand" body={CommonBodyForTable} ></Column>
                                {/* <Column field="subscription_startDate" header="Subscripttion Date" body={CommonBodyForTable} ></Column> */}
                                {/* <Column field="subDate" header="Subscription Date" body={CommonBodyForTable} ></Column> */}
                            </DataTable>
                        </div>
                    </div>
                </div>
            </Dialog>

        </>
    )
}

export default memo(StateWiseAllCupsChart);