import React, {useEffect, useRef, useState, memo} from 'react';
import { Chart } from 'primereact/chart';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { barDataForBarChart, barOptionsForBarChart } from '../Common/CommonFunctions';
import { CommonBodyForTable } from '../Common/CommonComponents';

const FlavorWiseChart = (props) => {

    const [loading,setLoading] = useState(true)
    const [flavourData,setFlavourData] = useState(null)
    const [flavourNameData,setFlavourNameData] = useState(null)
    const [flavourColorData,setFlavourColorData] = useState(null)
    const [allData,setAllData] = useState(null)

    const visitor = useRef(null);
    const dt = useRef(null);

    useEffect(()=>{
        setLoading(props.loading)
    },[props.loading])

    useEffect(()=>{
        getData(props.flavourChartData)
    },[props.flavourChartData])

    useEffect(()=>{
        getData(props.tapriFlavourChartData)
    },[props.tapriFlavourChartData])
    

    const getData = (data) =>{

        if(data){

            setAllData(data)

            let result = data.map(a => a.totalCup);
            let resultedNames = data.map(a => a._id);
            let resultedColors = data.map(a => a.color);
            
            setFlavourData(result)
            setFlavourNameData(resultedNames)
            setFlavourColorData(resultedColors)
        }
    }


    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const visitorChart = barDataForBarChart(flavourNameData,flavourData,'flavorwise Cup Consumption', flavourColorData )
    const visitorChartOptions = barOptionsForBarChart('No of Cups','Beverages')


    return (

        <>
            <div className="card widget-visitor-graph">

                <div className="card-header">
                    <span></span>
                    <div className='p-col-sm-4'>
                        <Button icon="pi pi-download" title='Download Report' className="p-button-rounded p-button-primary p-mr-2 p-mb-2" onClick={exportCSV} />
                    </div>
                </div>


                {
                    loading ? 
                    <div className='spinner-div'>
                        <center><ProgressSpinner strokeWidth={7} animationDuration='4s'/></center>
                    </div>
                    :
                    <div className="graph p-mt-5">
                        <Chart type="bar" data={visitorChart} options={visitorChartOptions} />
                        {/* <Chart ref={visitor} type="bar" data={visitorChart} options={visitorChartOptions} id="visitor-chart"></Chart> */}
                    </div>
                }

                <DataTable  style={{display:'none'}} ref={dt} value={allData} dataKey="id" exportFilename="Flavor-Wise Cup Consumption"  className="datatable-responsive" emptyMessage="No data found.">
                    <Column field="_id" header="Beverage Name"  body={CommonBodyForTable}></Column>
                    <Column field="totalCup" header="Total Cup Count" body={CommonBodyForTable}></Column>
                </DataTable>

            </div>

        </>
    );
}

export default memo(FlavorWiseChart);
