import React from "react";
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
import { MonthwisePnl } from "./MonthwisePnl";
import { CVESMonthWisePerformance } from "./CVESMonthWisePerformance";
import { MonthwiseAnalysisChart } from "./MonthwiseAnalysisChart";


export const MonthWisePerformance = () => {

    return (

        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <TabView>
                        <TabPanel header="Month Wise Performance (Range)">
                            <MonthwisePnl />
                        </TabPanel>
                        <TabPanel header="Month Wise Performance (CVES)">
                            <CVESMonthWisePerformance />
                        </TabPanel>
                        <TabPanel header="Performance Analysis">
                            <MonthwiseAnalysisChart />
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        </div>
    )
}