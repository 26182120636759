
import React, { useEffect, useRef, useState } from 'react';
import ApiServices from '../../service/api/ApiServices';
import CommonCal from '../../service/common/CommonCal';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from 'primereact/datatable';
import { CommonBodyForTable, NumberCommonBodyForTable, brandBodyForTable, commonCloseDialogFooter, nestedBodyForTable, nestedDateBodyForTable, srBodyTemplate } from '../Common/CommonComponents';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toolbar } from 'primereact/toolbar';
import { barDataForBarChart, barOptionsForBarChart, exportReport, getStartAndEndDateOfMonth } from '../Common/CommonFunctions';
import { Dropdown } from 'primereact/dropdown';
import { activityStatusOptions, brandsOptions, filterOptions } from './Constants';
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import { Chip } from 'primereact/chip';
import { Chart } from 'primereact/chart';

const commonCal = new CommonCal();
const apiServices = new ApiServices();

const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

export const MonthWisePullouts = () => {

    const durationOptions = [
        { name: 'All', code: 'all' },
        { name: 'Last Month', code: 'this_month' },
        { name: 'Last 3 Months', code: 'last_three_month' },
        { name: 'Last 6 Months', code: 'last_six_month' },
        { name: 'Last 12 Months', code: 'last_twelve_months' }
    ]

    const [loading, setLoading] = useState(false);
    const [globalFilter, setGlobalFilter] = useState('');
    const [selectedDuration, setSelectedDuration] = useState(durationOptions[1]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedbrand, setSelectedBrand] = useState(brandsOptions);
    const [btnVisibility, setBtnVisibility] = useState(false);
    const [dates, setDates] = useState(null);
    const [chartMasterData, setChartMasterData] = useState(null);
    const [labelsData, setLabelsData] = useState(null);
    const [valuesData, setValuesData] = useState(null);
    const [detailedDialog, setDetailedDialog] = useState(false);
    const [detailedData, setDetailedData] = useState(null)
    const [selectedBar, setSelectedBar] = useState(false)
    
    const dt = useRef(null);

    useEffect(() => {

        getDates();

    }, []);

    const getDates = async () => {
        const response = await commonCal.getDates();
        const date = { startDate: response?.lastStart, endDate: response?.endThisMonth }
        // setSelectedDate({ date: date });
        getReport({ date });
    }

    const getReport = async (data) => {
        setLoading(true);
        data.userType = initialValue.userType
        const response = await apiServices.getPullOutChart(data);

        const monthArray = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

        const lastData = response.data.data.map((element) => {
            return {
                ...element,
                month: `${monthArray[element?._id?.month - 1]} ${element?._id?.year}`
            }
        })

        setChartMasterData(lastData)

        const labelsArr = lastData.map(x => x.count);
        const valuesArr = lastData.map(x => x.month);

        setLabelsData(labelsArr);
        setValuesData(valuesArr);

        setLoading(false);
    }

    const leftToolbarTemplate = () => (
        <>
            <div className="p-inputgroup p-m-1">
                <Button className='custom-group-btn' label="BRAND" />
                <MultiSelect name='brand' value={selectedbrand} onChange={onChange} options={brandsOptions} optionLabel="name" display="chip" placeholder="Select Brand" maxSelectedLabels={3} filter />
            </div>
        </>
    )

    const rightToolbarTemplate = () => (
        <>
            <div className="p-inputgroup p-m-1">
                <Button className='custom-group-btn' label="DURATION" />
                <Dropdown value={selectedDuration} options={durationOptions} onChange={changeDuration} optionLabel="name" placeholder="Select Duration" filter className="p-m-0 multiselect-custom" />
            </div>

            <div className="p-inputgroup p-m-1">
                {btnVisibility && <Button icon="pi pi-filter" label='Filter' className="custom-group-btn p-button p-my-2 p-mx-2" onClick={filterData} />}
            </div>

        </>
    )

    const onChange = (e) => {
        if (e?.target?.name === 'brand') {
            setSelectedBrand(e.target.value);
            setBtnVisibility(true);
        }
    }

    const changeDuration = (e) => {
        // setLoading(true)
        setBtnVisibility(true)
        setSelectedDuration(e.target.value)

        const currdate = new Date();
        const nowDate = new Date().toISOString();

        switch (e.target.value.code) {
            case 'this_month':
                const lastOneStart = new Date(currdate.getFullYear(), currdate.getMonth() - 1, 1).setHours(0, 0, 0, 0);
                const lastStart1 = new Date(lastOneStart).toISOString();
                const dateLastMonth = { startDate: lastStart1, endDate: nowDate };
                setSelectedDate({ date: dateLastMonth })
                break;
            case 'last_three_month':
                const lastMonthStart = new Date(currdate.getFullYear(), currdate.getMonth() - 3, 1).setHours(0, 0, 0, 0);
                const lastStart = new Date(lastMonthStart).toISOString();
                const date = { startDate: lastStart, endDate: nowDate };
                setSelectedDate({ date: date })
                break;
            case 'last_six_month':
                const lastsix = new Date(currdate.getFullYear(), currdate.getMonth() - 6, 1).setHours(0, 0, 0, 0);
                const lastSixStart = new Date(lastsix).toISOString();
                const sixDate = { startDate: lastSixStart, endDate: nowDate };
                setSelectedDate({ date: sixDate })
                break;
            case 'last_twelve_months':
                const lastTwelve = new Date(currdate.getFullYear(), currdate.getMonth() - 12, 1).setHours(0, 0, 0, 0);
                const lastTwelveStart = new Date(lastTwelve).toISOString();
                const twelveDate = { startDate: lastTwelveStart, endDate: nowDate };
                setSelectedDate({ date: twelveDate })
                break;
            default:
                setSelectedDate(null)
        }
    }

    const filterData = () => {
        setBtnVisibility(false)
        const filteredData = selectedbrand.map((x) => { return x.code })
        getReport({ brand: filteredData, date: selectedDate?.date })
    }

    const barData = barDataForBarChart(valuesData, labelsData, 'CVES Pullout Count')

    const barOptionsForBarChart1 = (xLabel, yLabel) => {
        return {
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: 'rgba(160, 167, 181, .3)',
                    },
                    title: {
                        display: true,
                        text: xLabel
                    }
                },
                y: {
                    ticks: {
                        color: '#495057',
                        precision: 0
                    },
                    grid: {
                        color: 'rgba(160, 167, 181, .3)',
                    },
                    title: {
                        display: true,
                        text: yLabel
                    }
                }
            },
            onClick: function (e, clickedElement) {
                getBarData(clickedElement)

            }
        };
    }

    const getBarData = (data) => {
        if (data.length > 0) {
            setDetailedDialog(true)
            const chartIndex = chartMasterData[data[0].index];
            setSelectedBar(chartIndex)
            const month = chartIndex?._id?.month - 1;
            const year = chartIndex?._id?.year;
            const { startDate, endDate } = getStartAndEndDateOfMonth(month, year);
            // console.log(startDate + " - " + endDate);
            getChartReport(startDate, endDate)
        }
    }

    const getChartReport = async (startDate, endDate) => {

        const filteredData = selectedbrand.map((x) => { return x.code })
        const payload = { brand: filteredData, date: { startDate, endDate } }
        const response = await apiServices.getPullOutReport(payload);
        console.log(response?.data?.data);

        const reportData = response?.data?.data.map((x) => {
            const brandLookup = { 'TAPRI': 'Tapri Kiosk', 'TKAFFE': 'Tkaffe Kiosk', 'MERI_TAPRI': 'Tapri Kettle' };
            return { ...x, installedBrand: brandLookup[x.brand], subDate: x.subscription.startDate, endDate: x.subscription.endDate }
        })

        setDetailedData(reportData)

    }


    const hideDetailed = () => { setDetailedDialog(false) }
    const exportCSV = () => { dt.current.exportCSV(); }



    const sensorFooter = commonCloseDialogFooter('Close', hideDetailed)



    const barOptions = barOptionsForBarChart1('Months', 'CVES Pullout Count')


    const header = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <h6 className='p-mt-2'>Subscription Pullout Report</h6>
            </span >
            <span className="p-input-icon-right" >
                <div className="p-grid">
                    <div className='p-col-10'></div>
                    <div className='p-col-2'> <Button icon="pi pi-download" className='p-ml-100 custom-group-btn p-button-rounded' onClick={exportCSV} /></div>
                </div>
            </span>
        </div>
    );

    return (
        <>
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        {
                            loading ?
                                <>
                                    <div className="spinner-div">
                                        <center><ProgressSpinner strokeWidth="7" animationDuration='4s' /></center>
                                    </div>
                                </> :
                                <>
                                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate} ></Toolbar>
                                    <Chart type="bar" data={barData} options={barOptions} />
                                </>
                        }

                    </div>
                </div>
            </div>
            <Dialog className="p-fluid" header={`Detailed Report of ${selectedBar?.month}`} visible={detailedDialog} style={{ width: '50vw' }} footer={sensorFooter} onHide={hideDetailed} maximizable={true} blockScroll={true}>
                <div className="p-grid crud-demo">
                    <div className="p-col-12">
                        <div className="card">
                            <DataTable ref={dt} value={detailedData}
                                dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                                emptyMessage="No data found." globalFilter={globalFilter} header={header} exportFilename={`Report of ${selectedBar?.month} Installations`} >
                                <Column field="srid" header="Sr.No." body={srBodyTemplate} style={{ width: '4rem' }} exportable={false}></Column>
                                <Column field="id" header="CVES Id" body={CommonBodyForTable} sortable ></Column>
                                <Column field="subid" header="Sub Id" body={CommonBodyForTable} sortable ></Column>
                                <Column field="siteName" header="Account Name" body={CommonBodyForTable} sortable ></Column>
                                <Column field="installedBrand" header="Brand" body={CommonBodyForTable} sortable ></Column>
                                <Column field="totalCups" header="Total Cups" body={CommonBodyForTable} ></Column>
                                <Column field="subDate" header="Subscription Start Date" body={CommonBodyForTable} ></Column>
                                <Column field="endDate" header="Subscription End Date" body={CommonBodyForTable} ></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>

    )


}