import React, { useEffect, useState, useRef, useContext } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { Employees } from './Employees';
import { EmployeeCupConsumption } from './EmployeeCupConsumption';
import { VirtualCards } from './VirtualCards';
import { permissionContext } from '../../AppWrapper';
// Components
import { AddVirtualCards } from './AddVirtualCards';
// Common
import { successToast, failureToast } from '../Common/CommonFunctions';
import { commonFormDialogFooter } from '../Common/CommonComponents';
// Service
import ApiServices from '../../service/api/ApiServices';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { CommonBodyForTable } from '../Common/CommonComponents';
import { InputText } from 'primereact/inputtext';
import * as XLSX from 'xlsx';
import { FormWrapper, FullFormWrapper } from '../../utilComponents/Forms';
import { Checkbox } from "primereact/checkbox";

const apiServices = new ApiServices()

const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

// ============================================================================================
// ========================================Employee Component (Tab) ===========================
// =============================================================================================

export const EmployeeMain = () => {

    const rolePermissions = useContext(permissionContext);

    const [loading, setLoading] = useState([]);
    const [allMachines, setAllMachines] = useState([]);
    const [rfidMachines, setRFIDMachines] = useState([]);
    const [selectedMachine, setSelectedMachine] = useState([]);
    const [virtualCardMach, setVirtualCardMach] = useState([]);
    const [selectedVirtualMach, setSelectedVirtualMach] = useState([]);
    const [mchEmployee, setMchEmployee] = useState([]);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [addNfcDialog, setAddNfcDialog] = useState(false);
    const [virtualCardDialog, setVirtualCardDialog] = useState(false);
    const [employeeConsump, setEmployeeConsump] = useState(null)
    const [employeeDialog, setemployeeDialog] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [cardsData, setCardsData] = useState(null);
    const [flagForToolbar, setFlagForToolbar] = useState(false);
    const [triggerRFIDDialog, setTriggerRFIDDialog] = useState(false);

    const [globalFilter, setGlobalFilter] = useState(null);
    // excel upload data variables
    const [uploadExcelDialog, setUploadExcelDialog] = useState(false);
    const [excelRfidData, setExcelRfidData] = useState([]);
    const [editExcelDialog, setEditExcelDialog] = useState(false)
    const [excelRecord, setExcelRecord] = useState({})
    const [selectedRfid, setSelectedRfid] = useState(null)
    const [updatedCups, setUpdatedCups] = useState(null)
    const [isOnline, setIsOnline] = useState(false)
    const [cvesIds, setCvesIds] = useState([])
    // const [updatedRfid, setUpdatedRfid] = useState(null)
    // const [updatedName, setUpdatedName] = useState(null)




    const toast = useRef(null);
    const dt = useRef(null);
    const exceltable = useRef(null);

    useEffect(() => {
        getMachines()
    }, [])

    const getMachines = async () => {

        const response = await apiServices.findMachines();
        console.log(response?.data?.data);
        const response1 = await apiServices.getUserReduced();
        const userred = response1?.data?.data?.map((x) => { return x.userid })
        let arr = []
        response?.data?.data.map((x) => { if (userred.includes(x.customer)) { arr.push(x) } })
        const result = arr.filter((m) => { return m.siteName != '' });
        var resultedMachine = result.map((x) => {
            const subid = x.subid || 'CIPL';
            const address = response1.data.data.find(o => o.userid === x.customer)
            return {
                ...x,
                displayLabel: `${x.id} / ${subid} / ${address?.billing_details?.company}`,
                address: address?.addr
            }
        });

        console.log(resultedMachine);

        const empMachArr = []
        resultedMachine.filter((x) => {
            if (x.mchmode.paymode.onlineRfid || x.mchmode.paymode.nfc || x.mchmode.paymode.rfid) {
                empMachArr.push({ ...x })
            }
        })

        console.log(empMachArr);
        setAllMachines(resultedMachine)
        setRFIDMachines(empMachArr)
        setSelectedMachine(empMachArr[0])
        getEmployees(empMachArr[0])
        getEmployeeConFn({ filter: "chart", machineid: [empMachArr[0]?.id] })

        const virtual = resultedMachine.filter((x) => { if (x?.mchmode?.paymode?.password) { return x; } })
        setVirtualCardMach(virtual)
        setSelectedVirtualMach(virtual[0])
        console.log(virtual);
        getVirtualCards(virtual[0])
    }

    const getVirtualCards = async (data) => {
        console.log(data);
        const response = await apiServices.getVirtualCards({ machineId: data?.id })
        console.log(response?.data?.doc);
        setCardsData(response?.data?.doc)
        setLoading(false)
    }

    const getEmployeeConFn = async (data) => {
        const res = await apiServices.getEmployeeConsumption(data);
        console.log(res);
        let total = 0;
        let arr = []
        for (let i = 0; i < res?.data?.data?.length; i++) {
            total += res.data.data[i].cups;
            arr.push({ ...res.data.data[i], total: total })
        }
        setEmployeeConsump(arr);
        arr.length > 0 ? setFlagForToolbar(true) : setFlagForToolbar(false)
    }

    const getEmployeeConFn1 = async (data) => {
        const res = await apiServices.getEmployeeConsumption(data);
        console.log(res);
        let total = 0;
        let arr = []
        for (let i = 0; i < res?.data?.data?.length; i++) {
            total += res.data.data[i].cups;
            arr.push({ ...res.data.data[i], total: total })
        }
        setEmployeeConsump(arr);
    }

    const getEmployees = async (data) => {
        const res = await apiServices.getEmployee({ machineid: data?.id })
        console.log(res);
        setMchEmployee(res?.data?.doc)
        setLoading(false)
    }

    /**
     * This Function used to open employee dialog box
     */
    const openNew = () => { setemployeeDialog(true) }

    /**
     * This function used to open excel dialog 
     */
    const openExcelDialog = () => {
        setUploadExcelDialog(true);
        setExcelRfidData([]);
    }

    /**
     * this function used to hide excel dialog
     */
    const hideExcelDialog = () => { setUploadExcelDialog(false) }

    /**
     * This function used to hide excel update dialog
     */
    const hideExcelUpdateDialog = () => { setEditExcelDialog(false) }

    const updateExcelRecord = (event) => {
        console.log('updateExcelRecord');
        event.preventDefault();
        // Creating a new array where the object with matching RFID is updated
        let updatedExcelRfidData = excelRfidData.map((obj) => {
            if (obj.__rowNum__ === selectedRfid) {
                // Returning a new object by copying the properties of the old object and overriding the necessary fields
                return {
                    ...obj, // Keep all the properties of the original object
                    ...excelRecord // Override only the necessary properties from the excelRecord object
                };
            } else {
                return obj; // If RFID doesn't match, return the original object
            }
        });
    
        setExcelRfidData(updatedExcelRfidData); // sets the updated data
        hideExcelUpdateDialog(); // closes update dialog
    };
    

    /**
     * This function saves all excel rfid data
     */
    const saveExcelRfidData = async () => {
        console.log(isOnline,cvesIds)
        let data = { excelRfidData, isOnline, cvesIds };
        console.log("data", data);
        const res = await apiServices.saveEmployeeRfidData(data);
        res.data.success ? successToast(toast, "Success") : failureToast(toast, "Failed")
        setUploadExcelDialog(false)
    }


    // Excel dialog footer we have added close and submit button
    const excelDialogFooter = (
        <>
            <Button label="Close" icon="pi pi-times" className=" p-button-danger p-mr-2 p-mb-2" onClick={hideExcelDialog} />
            <Button label="Submit" icon="pi pi-plus-circle" className=" p-button-success p-mr-2 p-mb-2" onClick={saveExcelRfidData} />
        </>
    );

    // excel upadte dialog footer we have added close and update button
   
   
    const excelupdateDialogFooter = commonFormDialogFooter('Close', hideExcelUpdateDialog, 'Submit', 'update-Excel-form')


    // const excelupdateDialogFooter = (
    //     <>
    //         <Button label="Close" icon="pi pi-times" className=" p-button-danger p-mr-2 p-mb-2" onClick={hideExcelUpdateDialog} />
    //         <Button label="Update" icon="pi pi-check" className="p-button-success p-mr-2 p-mb-2" />

    //     </>
    // );

    const changeMachine = (e) => {
        console.log(e.value);
        setLoading(true)
        setSelectedMachine(e.value)
        getEmployees(e.value)
        getEmployeeConFn({ filter: "chart", machineid: [e.value?.id] })
        setTabIndex(0)
    }

    const deleteEmployee = async (data) => {
        const data1 = { rfid: data.rfid, machineid: data.machineid, cardtype: data.cardtype }
        const res = await apiServices.removeEmployee(data1)
        res.data.success ? successToast(toast, "Employee Deleted Successfully") : failureToast(toast, "Failed to Delete")
        setDeleteDialog(false)
        getEmployees(selectedMachine)
    }

    const onNfcSubmit = async (data) => {
        const res = await apiServices.updateEmployee(data)
        res.data.success ? successToast(toast, "Employee Details Updated Successfully") : failureToast(toast, `Failed to Update , ${res?.data?.msg}`)
        getEmployees(selectedMachine)
    }

    const openTrigger = () => { setTriggerRFIDDialog(true) }
    const hideTriggerDialog = () => { setTriggerRFIDDialog(false) }



    // const createTrigger = (status) =>{
    //     if(status == 'add'){
    //         triggerSubmit({ "id": selectedMachine?.id })
    //     }else{
    //         triggerSubmit({ "id": selectedMachine?.id, "updateCard": true})
    //     }
    // }

    const createTrigger = (status) => {
        const triggerData = { "id": selectedMachine?.id };
        if (status !== 'add') { triggerData.updateCard = true; };
        triggerSubmit(triggerData);
    };

    const triggerSubmit = async (data) => {
        const res = await apiServices.triggerRfidCards(data);
        res?.data?.success ? successToast(toast, 'Rfid triggered Successfully') : failureToast(toast, 'Failed to Trigger');
    }

    const exportCSV = () => { dt.current.exportCSV(); }

    /**
     * This Function Exports the excel file format to upload
     */
    const exportExcelRfid = () => { exceltable.current.exportCSV(); }


    const leftToolbarTemplate = () => {
        return (
            <div className="p-inputgroup" style={{ maxWidth: '70%' }}>
                <Button className='custom-group-btn' icon="pi pi-download" label="RFID CVES" onClick={exportCSV} />
                <Dropdown id='select-access' value={selectedMachine} options={rfidMachines} onChange={changeMachine} optionLabel="displayLabel" placeholder="Select CVES" filter className="p-mr-2 multiselect-custom" />
            </div>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <>
                {/* <Button label="Export" icon="pi pi-upload" className="p-button-help p-button-rounded p-mr-2" onClick={exportCSV} /> */}
                {(rolePermissions?.addEmployee && rfidMachines?.length > 0) && <Button label="Add New Employee" icon="pi pi-plus-circle" className="p-custom-btn p-mt-0" onClick={openNew} />}

                {
                    (rolePermissions?.triggerRfid && rfidMachines?.length > 0) &&
                    <Button label="Trigger RFID" icon="pi pi-upload" className="p-custom-btn p-ml-2" onClick={openTrigger} />
                }

                {

                    rolePermissions?.uploadRfid && <Button label="Upload Employee Excel" icon="pi pi-upload" className="p-custom-btn p-ml-2" onClick={openExcelDialog} />
                }

            </>
        )
    }

    const triggerDialogFooter = (
        <>
            <Button label="Close" icon="pi pi-times" className=" p-button-danger p-mr-2 p-mb-2" onClick={hideTriggerDialog} />
            <Button label="Create Trigger" icon="pi pi-plus-circle" className=" p-button-success p-mr-2 p-mb-2" onClick={() => { createTrigger('add') }} />
            <Button label="Update Trigger" icon="pi pi-upload" className=" p-button-success p-mr-2 p-mb-2" onClick={() => { createTrigger('update') }} />
        </>
    );

    /**
     * Handles Excel file upload
     * We are using this function to change excel data to json data
     * @param {*} event 
     */
    function handleFileChange(event) {
        // setFile(event.target.files[0])
        if( event.target && event.target.name == 'cvesids'){
            let result = event.target.value.split(',').filter(item => item !== "")
            setCvesIds(result)
            console.log(cvesIds)
        }
        if (event.target && event.target.files) {

            const isValid = validateFile(event.target.files[0]); // calling validate file function

            if (!isValid) {
                failureToast(toast, "Please upload excel file.")
                return
            }

            const reader = new FileReader();

            reader.onload = (event) => {
                const binaryStr = event.target.result;
                const workbook = XLSX.read(binaryStr, { type: 'binary' });
                // Assume the first sheet is the one we want to read
                const sheet = workbook.Sheets[workbook.SheetNames[0]];
                // Convert sheet to JSON
                const jsonData = XLSX.utils.sheet_to_json(sheet);
                // console.log('Excel JSON Data', jsonData);   
                setExcelRfidData(jsonData)
            };

            reader.readAsBinaryString(event.target.files[0]);
        }
    }

    /**
     * 
     * @param {file} We are validating if file is excel or not 
     * @returns 
     */
    const validateFile = (file) => {
        const allowedExtensions = ['.xls', '.xlsx', '.csv'];
        const extension = file.name.split('.').pop().toLowerCase();
        const mimeType = file.type;

        // Check if the file extension matches the allowed ones
        if (!allowedExtensions.includes(`.${extension}`)) {
            return false;
        }

        // Check MIME type for extra validation (optional)
        const validMimeTypes = [
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // for .xlsx files
            'application/vnd.ms-excel', // for .xls files
            'text/csv', // for .csv files
        ];

        if (!validMimeTypes.includes(mimeType)) {
            return false;
        }

        return true;
    };


    /**
     * Table header we are passing in main table
     */
    const excelTableHeader = (
        <div className="table-header p-grid">
            <div className="p-col-12 p-sm-12">
                <h6 className="p-mt-3">Employee</h6>
            </div>
            <div className="p-grid p-col-12">
                <div className="p-inputgroup p-col-10 p-sm-10">
                    <InputText type="text" id="cvesids" name='cvesids' onChange={handleFileChange} placeholder='CVES IDs' required />
                </div>
                <div className='p-col-2 p-sm-2'>
                    <div className="titleBar" style={{ display: "flex", alignItems: "center", justifyContent: "right", }}>
                        <Checkbox inputId="is_Online" className="p-mt-1" name="is_Online" onChange={() => setIsOnline(!isOnline)} checked={isOnline} />
                        <h6 className="p-mt-3" style={{ marginLeft: "10px" }}>
                            is Online
                        </h6>
                    </div>
                </div> 
            </div>
            <span className="p-col-12 p-sm-12">
                <div className="p-inputgroup">

                    <InputText type="file" id="File" name='File' onChange={handleFileChange} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,.xlsx,.xls" required />
                    {/* {
                        <Button label="Submit" icon="pi pi-money-bill" className="p-custom-btn p-m-1" onClick={() => console.log('clicked')} />
                    } */}
                    <Button icon="pi pi-download" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4' onClick={exportExcelRfid} />
                </div>
            </span>
        </div>
    );

    /**
     * Defines the action column, element and event
     * @param {} rowData 
     * @returns 
     */
    const actionOnExcelUploadTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Action</span>
                <div className="actions">
                    {<Button icon="pi pi-pencil" title='Edit' className="p-button-rounded p-button-success  p-mr-2 p-mb-2" onClick={() => editExcelDataDialog(rowData)} />}

                </div>
            </>
        );
    }

    /**
     * This function opens popup for update excel data
     * @param {*} rowData 
     */
    const editExcelDataDialog = (rowData) => {
        setEditExcelDialog(true)
        setExcelRecord(rowData)
        setSelectedRfid(rowData.__rowNum__)
        setUpdatedCups(rowData.cups)
    }

    /**
     * sets/prints ids
     * @param {*} rowData 
     * @param {*} props 
     * @returns 
     */
    const idBodyTemplate = (rowData, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {props.rowIndex + 1}
            </>
        );
    }


    useEffect(() => {
        console.log('Updated excelRecord', excelRecord);
    }, [excelRecord])

    /**
     * handles onchange event and updates excel record
     * @param {event} event 
     */
    const onChangeOfExcelRecord = (event) => {
        const { name, value } = event.target;

        setExcelRecord(prevState => {
            const updatedRecord = {
                ...prevState,
                [name]: value
            };
            return updatedRecord;
        });
    }

    return (

        <>
            <TabView >

                {
                    initialValue?.tabChecker?.rfidCheck ?
                        <TabPanel header="Employees">
                            <div className="p-grid crud-demo">
                                <div className="p-col-12">
                                    <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={tabIndex == 0 ? rightToolbarTemplate : null}></Toolbar>
                                    <div className="card">
                                        <TabView activeIndex={tabIndex} onTabChange={(e) => setTabIndex(e.index)}>
                                            <TabPanel header="List of Employees">
                                                <Employees
                                                    loading={loading}
                                                    mchEmployee={mchEmployee}
                                                    toast={toast}
                                                    deleteDialog={deleteDialog}
                                                    addNfcDialog={addNfcDialog}
                                                    deleteEmployee={deleteEmployee}
                                                    onNfcSubmit={onNfcSubmit}
                                                    allMachines={allMachines}
                                                    employeeDialog={employeeDialog}
                                                    setemployeeDialog={setemployeeDialog}
                                                    // success={success} 
                                                    // failure={failure} 
                                                    getEmployees={getEmployees}
                                                    selectedMachine={selectedMachine}
                                                />
                                            </TabPanel>
                                            <TabPanel header="Cup Consumption">
                                                <EmployeeCupConsumption
                                                    flagForToolbar={flagForToolbar}
                                                    mchEmployee={mchEmployee}
                                                    selectedMachine={selectedMachine}
                                                    employeeConsump={employeeConsump}
                                                    getEmployeeConFn={getEmployeeConFn1}
                                                />
                                            </TabPanel>
                                        </TabView>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        :
                        <TabPanel header=""></TabPanel>
                }

                {
                    initialValue?.tabChecker?.passwordCheck ?
                        <TabPanel header="Virtual Cards">
                            <div className='p-grid crud-demo'>
                                <div className='p-col-12'>
                                    <VirtualCards
                                        selectedMachine={selectedVirtualMach}
                                        toast={toast}
                                        virtualCardMach={virtualCardMach}
                                        // success={success} 
                                        // failure={failure} 
                                        cardsData={cardsData}
                                        getVirtualCards={getVirtualCards}
                                    />
                                </div>
                            </div>
                        </TabPanel>
                        :
                        <TabPanel header=""></TabPanel>
                }

            </TabView>

            <AddVirtualCards
                virtualCardDialog={virtualCardDialog}
                setVirtualCardDialog={setVirtualCardDialog}
                virtualCardMach={virtualCardMach}
                allMachines={allMachines}
            />

            <Dialog visible={triggerRFIDDialog} style={{ width: '450px' }} header="Confirm" modal footer={triggerDialogFooter} onHide={hideTriggerDialog} blockScroll={true}>
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error" style={{ fontSize: '4rem' }} /><br /><br />
                        <b><span>Are you sure you want trigger Create/Update RFID's.?</span></b> <br /><br />
                    </center>
                </div>
            </Dialog>


            <Dialog visible={uploadExcelDialog} style={{ width: '700px' }} header="Upload RFID Data Excel" modal footer={excelDialogFooter} onHide={hideExcelDialog} blockScroll={true}>
                <div className="p-grid crud-demo">
                    <div className="p-col-12">
                        <div className="card">
                            <DataTable dataKey="Sr.No." ref={exceltable} value={excelRfidData} header={excelTableHeader} className="datatable-responsive"
                                globalFilter={globalFilter} paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Employees"
                                emptyMessage="No data found.">
                                <Column field="Sr.No." header="Sr.No." body={idBodyTemplate} exportable={false}></Column>
                                {/* <Column field="CVES ID" header="CVES ID" body={CommonBodyForTable}></Column> */}
                                <Column field="RFID" header="RFID" body={CommonBodyForTable}></Column>
                                <Column field="Cups" header="Cups" body={CommonBodyForTable}></Column>
                                <Column field="Name" header="Name" body={CommonBodyForTable}></Column>
                                <Column body={actionOnExcelUploadTemplate} header="Action" exportable={false}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog visible={editExcelDialog} style={{ width: '550px' }} header={`Update RFID Details`} modal footer={excelupdateDialogFooter} className="p-fluid" onHide={hideExcelUpdateDialog} blockScroll={true}>
                <form onSubmit={updateExcelRecord} id="update-Excel-form">
                    <div className='p-grid'>
                        <FormWrapper id="rfid-fr" label="RFID">
                            <InputText id="RFID" type='text' name='RFID' defaultValue={excelRecord?.RFID} onChange={onChangeOfExcelRecord} placeholder='RFID' autoComplete="off" required />
                        </FormWrapper>
                        <FormWrapper label="Name">
                            <InputText id="Name" type='text' name='Name' defaultValue={excelRecord?.Name} onChange={onChangeOfExcelRecord} placeholder='Name' autoComplete="off" required />
                        </FormWrapper>
                        <FormWrapper label="Cups">
                            <InputText id="Cups" type='number' name='Cups' defaultValue={excelRecord?.Cups} onChange={onChangeOfExcelRecord} placeholder='Cups' autoComplete="off" required max={255} />
                        </FormWrapper>
                    </div>
                </form>
            </Dialog>

            <DataTable style={{ display: 'none' }} ref={dt} value={rfidMachines} dataKey="id" exportFilename="RFID Machines Data" className="datatable-responsive" emptyMessage="No data found.">
                <Column field="id" header="CVES Id" body={CommonBodyForTable}></Column>
                <Column field="subid" header="Sub Id" body={CommonBodyForTable}></Column>
                <Column field="siteName" header="Site Name" body={CommonBodyForTable}></Column>
            </DataTable>

        </>
    );
}







{/* <div className="p-grid crud-demo">
                <div className="p-col-12">
                <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={tabIndex==0?rightToolbarTemplate:null}></Toolbar>
                <div className="card">
                    <TabView activeIndex={tabIndex} onTabChange={(e) => setTabIndex(e.index)}>
                    <TabPanel header="Employees">
                        <Employees loading={loading} mchEmployee={mchEmployee} toast={toast} deleteDialog={deleteDialog} addNfcDialog={addNfcDialog} deleteEmployee={deleteEmployee} onNfcSubmit={onNfcSubmit} allMachines={allMachines} employeeDialog={employeeDialog} setemployeeDialog={setemployeeDialog} success={success} failure={failure} getEmployees={getEmployees} selectedMachine={selectedMachine} />
                    </TabPanel>
                    <TabPanel header="Cup Consumption">
                        <EmployeeCupConsumption mchEmployee={mchEmployee} selectedMachine={selectedMachine} employeeConsump={employeeConsump} getEmployeeConFn={getEmployeeConFn} />
                    </TabPanel>
                    </TabView>
                </div>
                </div>
            </div> */}