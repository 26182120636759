export const userTypeDropdown = [
    { name: 'All', code: 'all' },
    { name: 'Account', code: 'customer' },
    { name: 'Distributor', code: 'distributor' }
];

export const inventoryFilterItems = [
    { name: "Total Inventory", code: "total_inventory" },
    { name: "Remaining Inventory", code: "remaining_inventory" },
];

export const dateFilterItems = [
    { name: 'Till Date', code: 'till_date' },
    { name: 'Today', code: 'today' },
    { name: 'Yesterday', code: 'yesterday' },
    { name: 'This Week', code: 'this_week' },
    { name: 'This Month', code: 'this_month' },
    { name: 'Last Month', code: 'last_month' },
    { name: 'Custom', code: 'custom' }
];

export const viewFilterItems = [
    { name: 'Cups', code: 'cups' },
    { name: 'Packets', code: 'packets' }
];

export const materialStageValues = [
    { name: 'All', code:'all' },
    { name: 'Added', code:'added' },
    { name: 'Assigned', code:'assigned' },
    { name: 'Loaded', code:'loaded' },
    { name: 'Delivered', code:'delivered' },
    { name: 'Used', code:'used' }
];

export const stageOptions = [
    {name:'All',code:'all'},
    {name:'Allocated',code:'allocated'},
    {name:'Added',code:'added'},
    {name:'Assigned',code:'assigned'},
    {name:'Loaded',code:'loaded'},
    {name:'Delivered',code:'delivered'},
    {name:'Used',code:'used'}
]

export const deliveryModeOptions = [
    { name : 'Technoman', code : 'technoman'},
    { name : 'Logistic', code : 'logistic'}
]

export const unitOptions = [
    { name : 'Box', code: 'Box'},
    { name : 'Cm', code: 'Cm'},
    { name : 'Dz', code: 'Dz'},
    { name : 'Ft', code: 'Ft'},
    { name : 'g', code: 'G'},
    { name : 'In', code: 'In'},
    { name : 'Kg', code: 'Kg'},
    { name : 'Km', code: 'Km'},
    { name : 'Lb', code: 'Lb'},
    { name : 'Mg', code: 'Mg'},
    { name : 'M', code: 'M'},
    { name : 'Pcs', code: 'Pcs'}
]

export const manufacturerOptions = [
    { name : 'All Types', code: 'all_type'}
]

export const brandsOptions = [
    { name : 'All Types', code: 'all_type'}
]

export const accOptions = [
    { name : 'Bank Fee and Charges'},
    { name : 'Travel Expense'},
    { name : 'Parking'},
    { name : 'Automobile Expense'},
    { name : 'Lodging'}
]

export const orderTypeOptions = [
    { name : 'ALL'},
    { name : 'REFILL'},
    { name : 'ONETIME'}
]

export const PaymentConfirmation = [
    { name : 'Online', code : 'online'},
    { name : 'Cash', code : 'cash'}
]